import React, { useState } from 'react';
import axios from 'axios';
import logo from '../assets/img/logo.png';
import styles from './Login.module.css';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';

const Login = ({ LoginSetUser }) => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [error, setError] = useState(null);
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleCodeChange = (e) => {
    let value = e.target.value.replace(/\D/g, '');
    if (value.length > 6) {
      value = value.slice(0, 6);
    }
    setCode(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);
    setError(null);

    try {
      const action = step === 1 ? 'login' : 'verify_code';
      const payload = step === 1
        ? {
            action,
            email,
            password: CryptoJS.SHA256(password).toString().toLowerCase(),
          }
        : {
            action,
            email,
            code,
          };

      const response = await axios.post(
        'https://devkaioribeiro.com/apidipp/login.php',
        JSON.stringify(payload),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.success) {
        if (step === 1) {
          setStep(2);
        } else {
          console.log('Código de verificação válido');
          
          const userDataResponse = await axios.post(
            'https://devkaioribeiro.com/apidipp/login.php',
            JSON.stringify({ action: 'fetch_user_data', email }),
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );

          if (userDataResponse.data.success) {
            console.log('Dados do usuário recebidos:', userDataResponse.data.user);
            LoginSetUser(userDataResponse.data.user);

            // Redirecionar para o dashboard após o login bem-sucedido
            navigate('/dashboard');
          } else {
            setError(userDataResponse.data.message);
          }
        }
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      console.error('Erro ao conectar à API:', error);
      setError('Erro ao se conectar à API. Tente novamente mais tarde.');
    } finally {
      setLoad(false);
    }
  };

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.logoBox}>
          <img src={logo} alt="Logo" className={styles.logo} />
        </div>
        <center>
          <h1 className={styles.title}>DIPP</h1>
        </center>

        {step === 1 && (
          <>
            <div className={styles.formGroup}>
              <label htmlFor="email" className={styles.label}>Usuário</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                className={styles.input}
                required
              />
              <label htmlFor="password" className={styles.label}>Senha</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={handlePasswordChange}
                className={styles.input}
                required
              />
            </div>
            <button type="submit" disabled={load} className={styles.button}>
              {load ? "Verificando..." : "ENTRAR"}
            </button>
          </>
        )}

        {step === 2 && (
          <>
            <div className={styles.formGroup}>
              <label htmlFor="code" className={styles.label}>Código de Verificação</label>
              <input
                type="text"
                id="code"
                value={code}
                onChange={handleCodeChange}
                className={styles.input}
                style={{marginTop:10}}
                maxLength="6"
                required
              />
            </div>
            <button type="submit" disabled={load} className={styles.button}>
              {load ? "Verificando..." : "VERIFICAR"}
            </button>
          </>
        )}

        <br />
        {error && <center><p style={{ color: 'white', fontWeight:'bold', opacity:0.7, fontSize:14 }}><small>{error.toUpperCase()}</small></p></center>}
      </form>
    </div>
  );
};

export default Login;
