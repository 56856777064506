import React, { useEffect, useState } from 'react';
import './Editar.css';
import Default from '../assets/img/default_img.png';
import Default2 from '../assets/img/default_img2.png';
import Logo2 from '../assets/img/logo2.png';
import InputMask from 'react-input-mask';
import { LiaTrashSolid } from "react-icons/lia";
import { FaFacebook } from "react-icons/fa6";
import { RiInstagramFill } from "react-icons/ri";
import { FaSquareTwitter } from "react-icons/fa6";
import { SiTiktok } from "react-icons/si";


const Editar = ({ isOpen, onClose, idDoPreso }) => {
    // Estado para os campos do formulário
    const [imagem, setImagem] = useState(null);
    const [nome, setNome] = useState('');
    const [alcunha, setAlcunha] = useState('');
    const [pai, setPai] = useState('');
    const [mae, setMae] = useState('');
    const [infopen, setInfopen] = useState('');
    const [infoPessoal, setInfoPessoal] = useState('');
    const [cpf, setCpf] = useState('');
    const [identidade, setIdentidade] = useState('');
    const [cnh, setCnh] = useState('');
    const [dataNascimento, setDataNascimento] = useState('');
    const [posicao, setPosicao] = useState('');
    const [artigos, setArtigos] = useState('');
    const [localizacaoAtual, setLocalizacaoAtual] = useState('');
    const [advogados, setAdvogados] = useState([{ nome: '', oab: '' }]); // Estado para os advogados
    const [telefones, setTelefones] = useState([{ telefone: '', obs: '' }]); // Estado para os advogados
    const [pessoasEnvolvimento, setPessoasEnvolvimento] = useState([{ nome: '', relacao: '', obs: '' }]); // Estado para os advogados
    const [selecao, setSelecao] = useState([]);
    const [sexo, setSexo] = useState('Masculino');
    const [perfilAtual, setPerfilAtual] = useState(Default);
    const [presos, setPresos] = useState([
        { nome: '', processo: '', infopen: '' } // Estrutura inicial para um preso
    ]);
    const [dataPrisao, setDataPrisao] = useState('')
    const [dataProgressao, setDataProgressao] = useState('')
    const [areaAtuacao, setAreaAtuacao] = useState('')
    const [galeriaMonitorada, setGaleriaMonitorada] = useState('');
    const [faccaoCriminosa, setFaccaoCriminosa] = useState('');
    const [instagram, setInstagram] = useState('')
    const [facebook, setFacebook] = useState('')
    const [tiktok, setTiktok] = useState('')
    const [twitter, setTwitter] = useState('')
    const [score, setScore] = useState(0)
    const [galeriaMonitoradaValue, setGaleriaMonitoradaValue] = useState(0)
    const [faccaoCriminosaValue, setFaccaoCriminosaValue] = useState(0)
    const [totalValue, setTotalValue] = useState(0);
    const [posicaoValue, setPosicaoValue] = useState(0)
    const [showBox, setShowBox] = useState(false);
    const [dataFimPrisao, setDataFimPrisao] = useState('')
    const [activeTab, setActiveTab] = useState(1); // Controle das abas
    const [imagemExibicao, setImagemExibicao] = useState(null);

    const handleSelectChangeLocal = (event) => {
        setLocalizacaoAtual(event.target.value);
      };


    useEffect(() => {
        const carregarDadosPreso = async () => {
            try {
                const response = await fetch(`https://devkaioribeiro.com/apidipp/list_preso_id.php?id=${idDoPreso}`);
                const data = await response.json();

                // Preencher os campos com os dados recebidos
                setNome(data.nome || '');
                setImagem(data.imagem || '');
                setAlcunha(data.alcunha || '');
                setPai(data.pai || '');
                setMae(data.mae || '');
                setInfopen(data.infopen || '');
                setCpf(data.cpf || '');
                setIdentidade(data.identidade || '');
                setCnh(data.cnh || '');
                setDataNascimento(data.dataNascimento || '');
                setArtigos(data.artigos || '');
                setLocalizacaoAtual(data.localizacaoAtual || '');
                setAdvogados(Array.isArray(data.advogados) ? data.advogados : JSON.parse(data.advogados || '[]'));
                setTelefones(Array.isArray(data.telefones) ? data.telefones : JSON.parse(data.telefones || '[]'));
                setPessoasEnvolvimento(Array.isArray(data.pessoasEnvolvimento) ? data.pessoasEnvolvimento : JSON.parse(data.pessoasEnvolvimento || '[]'));
                setPresos(Array.isArray(data.presos) ? data.presos : JSON.parse(data.presos || '[]'));
                const selecaoData = Array.isArray(data.selecao) ? data.selecao : JSON.parse(data.selecao || '[]');
                setSelecao(selecaoData);

                const newSelectedValues = { ...selectedValues };
                selecaoData.forEach((item) => {
                    newSelectedValues[item] = weights[item] || 0;
                });
                setSelectedValues(newSelectedValues);
                setInfoPessoal(data.infoPessoal || '');
                setAreaAtuacao(data.areaAtuacao || '');
                setInstagram(data.instagram || '');
                setFacebook(data.facebook || '');
                setTiktok(data.tiktok || '');
                setTwitter(data.twitter || '');
                setScore(data.score || 0);
                setSexo(data.sexo || 'Masculino');
                setGaleriaMonitorada(data.galeriaMonitorada || '');
                setFaccaoCriminosa(data.faccaoCriminosa || '');
                setPosicao(data.posicao || '');
                setDataPrisao(data.dataPrisao || '');
                setDataProgressao(data.dataProgressao || '');
                setDataFimPrisao(data.dataFimPrisao || '');

                if (pessoasEnvolvimento == '') {
                    setPessoasEnvolvimento([{ nome: '', relacao: '', obs: '' }]); // Estado para os advogados
                }



            } catch (error) {
                console.error('Erro ao carregar os dados', error);
            }
        };

        carregarDadosPreso();
    }, [idDoPreso], []);




    const [selectedValues, setSelectedValues] = useState({
        " É condenado ou responde pelos principais crimes hediondos": 0,
        " É reincidente na prática de crime hediondo": 0,
        " Foi preso mais de uma vez, independente do crime": 0,
        " Possui registro ou responde processos em mais de uma Comarca": 0,
        " Responde PAD por ameaça a servidores": 0,
        " Responde PAD por subversão à ordem e/ou disciplina em Unidades Prisionais": 0,
        " Possui registro de participação em fugas, motins ou rebeliões em Unidades Prisionais": 0,
        " Desobedeceu a servidores ou desrespeitou qualquer pessoa com quem devesse relacionar-se": 0,
        " Usou, forneceu ou foi encontrado com aparelho telefônico, rádio ou similar, consigo ou em sua cela": 0,
        " Envolveu-se em homicídio durante cumprimento de pena em Unidades Prisionais": 0,
        " Descumpriu normas internas da Unidade ainda que dado prévio conhecimento": 0,
        " Impediu, retardou ou deixou de praticar qualquer procedimento interno": 0,
        " Portou, produziu ou fabricou, dentro da Unidade Prisional, objeto capaz de ferir servidores ou outros internos": 0,
        " Utilizou meios ilícitos, com ou sem ajuda de terceiros, para se comunicar ou enviar mensagens para fora da Unidade Prisional": 0,
        " Faz uso de medicamento controlado": 0,
        " Simulou doença ou estado de precariedade física para obter algum tipo de vantagem": 0,
        " Possui registro de evasão": 0,
        " Evadiu-se mais de uma vez": 0,
        " Descumpriu, em regime semiaberto, normas e condições prescritas de trabalho externo ou saida temporária": 0,
        " Responde por tentativa de fuga": 0,
        " Possui registro de fuga": 0,
        " Fugiu mais de uma vez": 0,
        " Tem registro de fuga ou tentativa de fuga por abuso de confiança": 0,
        " Cumpriu pena em alguma Unidade Prisional do DEPEN/ SPF (Sistema  Penitenciario Federal)": 0,
        " Possui alto poder aquisitivo": 0,
        " Antes da preso, ocupava lugar em lista dos mais procurados do estado": 0,
        " Membros de sua familia cumpriram ou cumprem pena em Unidade Prisional": 0,
        " Foi preso pelo crime de tráfico de drogas": 0,
        " É reincidente na prática de crime de tráfico de drogas": 0,
        " Ocupava posto de vapor, olheiro ou embalador na hierarquia do tráfico até o momento da prisão": 0,
        " Ocupava posto de dono, gerente-geral, gerente ou soldado na hierarquia do tráfico até o momento da prisão": 0,
        " É identificado como líder de Organização Criminosa": 0,
        " Exerce, ainda que preso, influência ou controle na comunidade onde vivia ou atuava": 0,
        " Possui participação ou é suspeito de crimes cuja execução foi planejada dentro de Unidade Prisional": 0,
        " Participou de crimes contra instituição financeira, sendo eles: roubo a banco, carro forte, explosão de caixa eletrônico e crime do sapatinho": 0,
        " Possui perfil de liderança ou poder de decisão dentro da Unidade Prisional onde cumpre pena": 0,
        " Cometeu crime de forma cruel, mediante tortura ou com requintes de brutalidade": 0,
        " Possui participação direta em crimes de repercussão nas mídias nacional ou internacional": 0,
        " Apresenta ausência de requisitos que demonstrem compromisso com a ressocialização": 0,
        " Incitou ou atuou como líder em articulação de fugas, motins ou rebeliões em Unidades Prisionais": 0,

    });

    const weights = {
        " É condenado ou responde pelos principais crimes hediondos": 10,
        " É reincidente na prática de crime hediondo": 10,
        " Foi preso mais de uma vez, independente do crime": 6,
        " Possui registro ou responde processos em mais de uma Comarca": 5,
        " Responde PAD por ameaça a servidores": 10,
        " Responde PAD por subversão à ordem e/ou disciplina em Unidades Prisionais": 4,
        " Possui registro de participação em fugas, motins ou rebeliões em Unidades Prisionais": 9,
        " Desobedeceu a servidores ou desrespeitou qualquer pessoa com quem devesse relacionar-se": 4,
        " Usou, forneceu ou foi encontrado com aparelho telefônico, rádio ou similar, consigo ou em sua cela": 5,
        " Envolveu-se em homicídio durante cumprimento de pena em Unidades Prisionais": 10,
        " Descumpriu normas internas da Unidade ainda que dado prévio conhecimento": 3,
        " Impediu, retardou ou deixou de praticar qualquer procedimento interno": 4,
        " Portou, produziu ou fabricou, dentro da Unidade Prisional, objeto capaz de ferir servidores ou outros internos": 7,
        " Utilizou meios ilícitos, com ou sem ajuda de terceiros, para se comunicar ou enviar mensagens para fora da Unidade Prisional": 10,
        " Faz uso de medicamento controlado": 2,
        " Simulou doença ou estado de precariedade física para obter algum tipo de vantagem": 3,
        " Possui registro de evasão": 6,
        " Evadiu-se mais de uma vez": 8,
        " Descumpriu, em regime semiaberto, normas e condições prescritas de trabalho externo ou saida temporária": 5,
        " Responde por tentativa de fuga": 7,
        " Possui registro de fuga": 8,
        " Fugiu mais de uma vez": 9,
        " Tem registro de fuga ou tentativa de fuga por abuso de confiança": 7,
        " Cumpriu pena em alguma Unidade Prisional do DEPEN/ SPF (Sistema  Penitenciario Federal)": 6,
        " Possui alto poder aquisitivo": 4,
        " Antes da preso, ocupava lugar em lista dos mais procurados do estado": 7,
        " Membros de sua familia cumpriram ou cumprem pena em Unidade Prisional": 3,
        " Foi preso pelo crime de tráfico de drogas": 6,
        " É reincidente na prática de crime de tráfico de drogas": 7,
        " Ocupava posto de vapor, olheiro ou embalador na hierarquia do tráfico até o momento da prisão": 5,
        " Ocupava posto de dono, gerente-geral, gerente ou soldado na hierarquia do tráfico até o momento da prisão": 8,
        " É identificado como líder de Organização Criminosa": 10,
        " Exerce, ainda que preso, influência ou controle na comunidade onde vivia ou atuava": 9,
        " Possui participação ou é suspeito de crimes cuja execução foi planejada dentro de Unidade Prisional": 9,
        " Participou de crimes contra instituição financeira, sendo eles: roubo a banco, carro forte, explosão de caixa eletrônico e crime do sapatinho": 8,
        " Possui perfil de liderança ou poder de decisão dentro da Unidade Prisional onde cumpre pena": 8,
        " Cometeu crime de forma cruel, mediante tortura ou com requintes de brutalidade": 10,
        " Possui participação direta em crimes de repercussão nas mídias nacional ou internacional": 9,
        " Apresenta ausência de requisitos que demonstrem compromisso com a ressocialização": 6,
        " Incitou ou atuou como líder em articulação de fugas, motins ou rebeliões em Unidades Prisionais": 10,

    };

    useEffect(() => {
        // Verifica galeriaMonitorada
        if (galeriaMonitorada == 'sim') {
            setGaleriaMonitoradaValue(8);
        } else {
            setGaleriaMonitoradaValue(0);
        }

        // Verifica posicao
        if (posicao == 'JET') {
            setPosicaoValue(4);
        } else if (posicao == 'SALVEIRO') {
            setPosicaoValue(4);
        } else if (posicao == 'DISCIPLINA') {
            setPosicaoValue(6);
        } else if (posicao == 'GALERIA') {
            setPosicaoValue(6);
        } else if (posicao == 'PREDIO') {
            setPosicaoValue(8);
        } else {
            setPosicaoValue(0); // Valor padrão caso nenhum dos valores seja atendido
        }

        // Verifica faccaoCriminosa
        if (faccaoCriminosa) {
            setFaccaoCriminosaValue(8);
        } else {
            setFaccaoCriminosaValue(0);
        }
    }, [galeriaMonitorada, posicao, faccaoCriminosa]);


    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    useEffect(() => {
        const selectedItems = Object.keys(selectedValues).filter(
            (item) => selectedValues[item] !== 0
        );
        setSelecao(selectedItems); // Armazena os itens selecionados (com valor diferente de 0)
        console.log(selecao);
    }, [selectedValues]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY + window.innerHeight;
            const pageHeight = document.documentElement.scrollHeight;

            if (scrollPosition >= pageHeight / 1.5) {
                setShowBox(true);
            } else {
                setShowBox(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useEffect(() => {
        if (galeriaMonitorada == 'sim') {
            setGaleriaMonitoradaValue(8)
        }
        if (faccaoCriminosa != '') {
            setFaccaoCriminosaValue(8)
        }
        if (posicao == 'JET') {
            setPosicaoValue(4)
        }
        if (posicao == 'SALVEIRO') {
            setPosicaoValue(4)
        }
        if (posicao == 'DISCIPLINA') {
            setPosicaoValue(6)
        }
        if (posicao == 'GALERIA') {
            setPosicaoValue(6)
        }
        if (posicao == 'PREDIO') {
            setPosicaoValue(8)
        }

    }, [])

    useEffect(() => {
        if (galeriaMonitorada == 'sim') {
            setGaleriaMonitoradaValue(8)
        }
        if (faccaoCriminosa != '') {
            setFaccaoCriminosaValue(8)
        }
        if (posicao == 'JET') {
            setPosicaoValue(4)
        }
        if (posicao == 'SALVEIRO') {
            setPosicaoValue(4)
        }
        if (posicao == 'DISCIPLINA') {
            setPosicaoValue(6)
        }
        if (posicao == 'galeria') {
            setPosicaoValue(6)
        }
        if (posicao == 'PREDIO') {
            setPosicaoValue(8)
        }
        setScore(totalValue + galeriaMonitoradaValue + faccaoCriminosaValue + posicaoValue)
    }, [totalValue, galeriaMonitoradaValue, faccaoCriminosaValue, posicaoValue])





    const handleCheckboxChange = (item) => {
        setSelectedValues((prevValues) => {
            const newValue = prevValues[item] === 0 ? weights[item] : 0;
            return {
                ...prevValues,
                [item]: newValue,
            };
        });
    };





    // useEffect para calcular e atualizar o totalValue sempre que selectedValues mudar
    useEffect(() => {
        const newTotal = Object.values(selectedValues).reduce((a, b) => a + b, 0);
        setTotalValue(newTotal);
    }, [selectedValues]); // O useEffect será disparado quando selectedValues mudar

    const getBackgroundColor = (totalValue) => {
        // Definindo os limites e cores correspondentes
        const limits = [
            { max: 50, color: '#00FF00' }, // Verde (baixíssima periculosidade)
            { max: 100, color: '#FFFF00' }, // Azul (baixa periculosidade)
            { max: 150, color: '#FFA500' }, // Amarelo (periculosidade moderada/média)
            { max: 200, color: '#ff8100' }, // Laranja (alta periculosidade)
            { max: 300, color: '#FF0000' } // Vermelho (altíssima periculosidade)
        ];

        // Função para interpolar entre duas cores
        const interpolateColor = (color1, color2, factor) => {
            const hexToRgb = (hex) =>
                hex.match(/\w\w/g).map(x => parseInt(x, 16));

            const rgbToHex = (rgb) =>
                `#${rgb.map(x => x.toString(16).padStart(2, '0')).join('')}`;

            const [r1, g1, b1] = hexToRgb(color1);
            const [r2, g2, b2] = hexToRgb(color2);

            const r = Math.round(r1 + (r2 - r1) * factor);
            const g = Math.round(g1 + (g2 - g1) * factor);
            const b = Math.round(b1 + (b2 - b1) * factor);

            return rgbToHex([r, g, b]);
        };

        // Encontrando a cor correta baseada no totalValue
        for (let i = 0; i < limits.length - 1; i++) {
            if (totalValue <= limits[i].max) {
                const rangeStart = i === 0 ? 0 : limits[i - 1].max;
                const rangeEnd = limits[i].max;
                const factor = (totalValue - rangeStart) / (rangeEnd - rangeStart);

                // Garantindo que o acesso ao índice anterior seja válido
                const colorStart = i === 0 ? limits[0].color : limits[i - 1].color;
                const colorEnd = limits[i].color;

                return interpolateColor(colorStart, colorEnd, factor);
            }
        }

        // Se totalValue for maior que o maior limite, retorna a última cor
        return limits[limits.length - 1].color;
    };

    // Exemplo de uso
    const backgroundColor = getBackgroundColor(totalValue);



    console.log(backgroundColor);


    // Atualiza a variável selecao com os itens marcados



    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        setGaleriaMonitorada(selectedValue)
        if (selectedValue == 'sim') {
            setGaleriaMonitoradaValue(8)
        } else { setGaleriaMonitoradaValue(0) }
    }


    const handleSelectChangeFaccao = (event) => {
        const selectedValue = event.target.value;
        setFaccaoCriminosa(selectedValue);
        if (selectedValue != '') {
            setFaccaoCriminosaValue(8)
        } else { setFaccaoCriminosaValue(0) }
    };


    const handleSelectChangePosicao = (event) => {
        const selectedValue = event.target.value;
        setPosicao(selectedValue);

        if (selectedValue == 'JET') {
            setPosicaoValue(4)
        }
        if (selectedValue == 'SALVEIRO') {
            setPosicaoValue(4)
        }
        if (selectedValue == 'DISCIPLINA') {
            setPosicaoValue(6)
        }
        if (selectedValue == 'GALERIA') {
            setPosicaoValue(6)
        }
        if (selectedValue == 'PREDIO') {
            setPosicaoValue(8)
        }
    };

    const handlePresoChange = (index, field, value) => {
        const novosPresos = [...presos];
        novosPresos[index][field] = value;
        setPresos(novosPresos);
    };

    const handleTelefoneChange = (index, field, value) => {
        const novosTelefones = [...telefones];
        novosTelefones[index][field] = value;
        setTelefones(novosTelefones);
    };
    const handlePessoasEnvolvimentoChange = (index, field, value) => {
        const novasPessoasEnvolvimento = [...pessoasEnvolvimento];
        novasPessoasEnvolvimento[index][field] = value;
        setPessoasEnvolvimento(novasPessoasEnvolvimento);
    };

    const handleAddPreso = () => {
        setPresos([...presos, { nome: '', processo: '', infopen: '' }]);
    };
    const handleAddPessoasEnvolvimento = () => {
        setPessoasEnvolvimento([...pessoasEnvolvimento, { nome: '', relacao: '', obs: '' }]);
    };
    const handleAddTelefone = () => {
        setTelefones([...telefones, { telefone: '', complemento: '' }]);
    };

    // Função para remover um preso
    const handleRemovePreso = (index) => {
        const novosPresos = presos.filter((_, i) => i !== index);
        setPresos(novosPresos);
    };
    const handleRemovePessoasEnvolvimento = (index) => {
        const novasPessoasEnvolvimento = pessoasEnvolvimento.filter((_, i) => i !== index);
        setPessoasEnvolvimento(novasPessoasEnvolvimento);
    };


    const handleRemoveAdvogado = (index) => {
        const newAdvogados = advogados.filter((_, i) => i !== index);
        setAdvogados(newAdvogados);
    };
    const handleRemoveTelefones = (index) => {
        const newTelefones = telefones.filter((_, i) => i !== index);
        setTelefones(newTelefones);
    };

    useEffect(() => {
        if (sexo === 'Masculino') {
            setPerfilAtual(Default);
        } else {
            setPerfilAtual(Default2);
        }
    }, [sexo]);

    // Função para lidar com o upload da imagem
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const localUrl = URL.createObjectURL(file);
            setImagem(localUrl);
        }
    };


    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file) {
            setImagem(URL.createObjectURL(file));
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    // Função para lidar com mudanças nos campos de advogados
    const handleAdvogadoChange = (index, field, value) => {
        const newAdvogados = [...advogados];
        newAdvogados[index][field] = value;
        setAdvogados(newAdvogados);
    };

    // Função para adicionar mais campos de advogados
    const handleAddAdvogado = () => {
        setAdvogados([...advogados, { nome: '', oab: '' }]);
    };

    const handleRegister = async (e) => {
        e.preventDefault();

        // Criando o FormData e adicionando os campos
        const formData = new FormData();

        formData.append('id', idDoPreso);
        formData.append('imagem', e.target.imagem.files[0]);
        formData.append('nome', nome);
        formData.append('alcunha', alcunha);
        formData.append('pai', pai);
        formData.append('mae', mae);
        formData.append('infopen', infopen);
        formData.append('infoPessoal', infoPessoal);
        formData.append('cpf', cpf);
        formData.append('identidade', identidade);
        formData.append('cnh', cnh);
        formData.append('dataNascimento', dataNascimento);
        formData.append('artigos', artigos);
        formData.append('posicao', posicao);
        formData.append('localizacaoAtual', localizacaoAtual);
        formData.append('advogados', JSON.stringify(advogados)); // Convertendo para string JSON
        formData.append('telefones', JSON.stringify(telefones)); // Convertendo para string JSON
        formData.append('presos', JSON.stringify(presos));       // Convertendo para string JSON
        formData.append('selecao', JSON.stringify(selecao));     // Convertendo para string JSON
        formData.append('pessoasEnvolvimento', JSON.stringify(pessoasEnvolvimento));
        formData.append('sexo', sexo);
        formData.append('dataPrisao', dataPrisao);
        formData.append('dataProgressao', dataProgressao);
        formData.append('areaAtuacao', areaAtuacao);
        formData.append('galeriaMonitorada', galeriaMonitorada);
        formData.append('faccaoCriminosa', faccaoCriminosa);
        formData.append('instagram', instagram);
        formData.append('facebook', facebook);
        formData.append('tiktok', tiktok);
        formData.append('twitter', twitter);
        formData.append('score', score);
        formData.append('dataFimPrisao', dataFimPrisao);

        try {
            // Enviando os dados para a API
            const response = await fetch('https://devkaioribeiro.com/apidipp/post_edit_dados.php', {
                method: 'POST',
                body: formData,
            });

            // Log do status da resposta HTTP
            console.log('Status da resposta:', response.status);
            console.log(FormData)
            // Captura a resposta como texto
            const text = await response.text();
            console.log('Resposta do servidor:', text);

            // Verifica se o status da resposta é 200 (OK)
            if (!response.ok) {
                throw new Error(`Erro HTTP: ${response.status} - ${response.statusText}`);
            }

            // Tenta fazer o parse do JSON
            try {
                const result = JSON.parse(text);
                console.log('Resultado:', result);

                if (result.success) {
onClose()
                } else if (result.error) {
                    alert(`Erro no cadastro: ${result.error}`);
                }
            } catch (parseError) {
                console.error('Erro ao fazer parse do JSON:', parseError.message);
                alert('Resposta inválida do servidor. Verifique os dados enviados.');
            }

        } catch (error) {
            // Lida com erros de requisição
            console.error('Erro na requisição:', error.message);
            alert(`Erro ao enviar os dados: ${error.message}`);
        }
    };
    useEffect(() => {
        if (imagem) {
            if (imagem.startsWith('blob:')) {
                // Se for uma URL local (imagem recém-selecionada)
                setImagemExibicao(imagem);
            } else {
                // Se for o caminho da imagem no servidor
                setImagemExibicao(`https://devkaioribeiro.com/apidipp/${imagem}`);
            }
        } else {
            // Caso não haja imagem, define como null ou imagem padrão
            setImagemExibicao(null);
        }
    }, [imagem]);

    const resetForm = () => {
        setImagem(null);
        setNome('');
        setAlcunha('');
        setPai('');
        setMae('');
        setInfopen('');
        setInfoPessoal('');
        setCpf('');
        setIdentidade('');
        setCnh('');
        setDataNascimento('');
        setPosicao('');
        setArtigos('');
        setLocalizacaoAtual('');
        setAdvogados([{ nome: '', oab: '' }]);
        setTelefones([{ telefone: '', obs: '' }]);
        setPessoasEnvolvimento([{ nome: '', relacao: '', obs: '' }]);
        setSelecao([]);
        setSexo('Masculino');
        setPerfilAtual(Default);
        setPresos([{ nome: '', processo: '', infopen: '' }]);
        setDataPrisao('');
        setDataProgressao('');
        setAreaAtuacao('');
        setGaleriaMonitorada('');
        setFaccaoCriminosa('');
        setInstagram('');
        setFacebook('');
        setTiktok('');
        setTwitter('');
        setScore(0);
        setGaleriaMonitoradaValue(0);
        setFaccaoCriminosaValue(0);
        setTotalValue(0);
        setPosicaoValue(0);
        setShowBox(false);
        setDataFimPrisao('');
        setActiveTab(1);
        setImagemExibicao(null);
        setSelectedValues({
            " É condenado ou responde pelos principais crimes hediondos": 0,
            " É reincidente na prática de crime hediondo": 0,
            " Foi preso mais de uma vez, independente do crime": 0,
            // Continue com os outros campos do selectedValues
        });
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            resetForm()
            onClose();
        }
    };
    return (
        <div className="modal-overlay" contentLabel="Editar Registro" onClick={handleOverlayClick}>

            <div className="cadastro-container">
                <div className="modal-content">

                    <div className="cadastro-box" style={{ marginBottom: '-80px' }}>

                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",

                                borderBottom: "2px solid #d3d3d3",
                                paddingBottom: "10px",
                                width: "100%",
                                boxSizing: "border-box",
                                position: "relative",
                            }}
                        >
                            <img
                                src={Logo2}
                                alt="Preview"
                                style={{
                                    height: "100px",
                                    position: "absolute",
                                    left: "20px",
                                    opacity: 0.3,
                                    marginTop: -20,
                                }}
                            />
                            <h1
                                style={{
                                    fontSize: "2.8rem",
                                    color: "#d3d3d3",
                                    letterSpacing: "1.5px",
                                    textTransform: "uppercase",
                                    margin: 0,
                                    fontFamily: "'Arial', sans-serif",
                                    textAlign: "center",
                                    width: "100%",
                                    opacity: 0.7,
                                    marginBottom: "20px",
                                }}
                            >
                                EDIÇÃO
                            </h1>
                        </div>

                        <form onSubmit={handleRegister}>
                            <div
                                className="input-group image-upload"
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                            >
                                <center>
                                    <label htmlFor="imagem" className="upload-label">
                                        {imagemExibicao ? (
                                            <img
                                                src={imagemExibicao}
                                                alt="Preview"
                                                className="image-preview"
                                                style={{
                                                    height: 200,
                                                    borderRadius: '8px',
                                                    border: '1px solid #ccc',
                                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                    cursor: 'pointer',
                                                    marginBottom: 20,
                                                    marginTop: 60,
                                                }}
                                            />
                                        ) : (
                                            <div>
                                                <img
                                                    src={perfilAtual}
                                                    alt="Preview"
                                                    className="image-preview"
                                                    style={{
                                                        height: 200,
                                                        borderRadius: '8px',
                                                        border: '1px solid #ccc',
                                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                        cursor: 'pointer',
                                                        marginBottom: 20,
                                                        marginTop: 60,
                                                        opacity: 0.4
                                                    }}
                                                />
                                                <h3 style={{ opacity: 0.3 }}>
                                                    Arraste e solte a imagem ou clique para selecionar
                                                </h3>
                                            </div>

                                        )}
                                        <br></br>

                                        <input
                                            id="imagem"
                                            name="imagem"
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                            style={{ display: 'none' }}
                                        />
                                    </label>
                                </center>
                            </div>

                            {/* Outros campos do formulário */}
                            <div className="input-group">
                                <label htmlFor="nome">Nome</label>
                                <input
                                    id="nome"
                                    type="text"
                                    value={nome}
                                    onChange={(e) => setNome(e.target.value.toUpperCase())}
                                    required
                                />
                            </div>

                            <div className="input-group">
                                <label>Sexo</label>
                                <div className="radio-group" style={{ display: 'flex' }}>
                                    <label>
                                        <input
                                            type="radio"
                                            name="sexo"
                                            value="Masculino"
                                            checked={sexo === 'Masculino'}
                                            onChange={(e) => setSexo(e.target.value)}
                                        />
                                        Masculino
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            name="sexo"
                                            value="Feminino"
                                            checked={sexo === 'Feminino'}
                                            onChange={(e) => setSexo(e.target.value)}
                                            style={{ marginLeft: '30px' }}
                                        />
                                        Feminino
                                    </label>
                                </div>
                            </div>

                            <div className="input-group">
                                <label htmlFor="alcunha">Alcunha</label>
                                <input
                                    id="alcunha"
                                    type="text"
                                    value={alcunha}
                                    onChange={(e) => setAlcunha(e.target.value.toUpperCase())}
                                    required
                                />
                            </div>

                            <div className="input-group">
                                <label htmlFor="pai">Pai</label>
                                <input
                                    id="pai"
                                    type="text"
                                    value={pai}
                                    onChange={(e) => setPai(e.target.value.toUpperCase())}
                                />
                            </div>

                            <div className="input-group">
                                <label htmlFor="mae">Mãe</label>
                                <input
                                    id="mae"
                                    type="text"
                                    value={mae}
                                    onChange={(e) => setMae(e.target.value.toUpperCase())}
                                />
                            </div>

                            <div className="input-group">
                                <label htmlFor="infopen">Endereço</label>
                                <input
                                    id="infopen"
                                    type="text"
                                    value={infoPessoal}
                                    onChange={(e) => setInfoPessoal(e.target.value.toUpperCase())}
                                />
                            </div>
                            <div className="input-group">
                                <label htmlFor="infopen">Infopen</label>
                                <InputMask
                                    id="infopen"

                                    value={infopen}
                                    onChange={(e) => setInfopen(e.target.value)}
                                    style={{
                                        marginBottom: '20px',
                                        textAlign: 'left',
                                        width: '33%',
                                        padding: '10px',
                                        border: '1px solid #ccc',
                                        bordeRadius: '15px',
                                        boxSizing: 'border-box',
                                        letterSpacing: 1
                                    }}
                                />
                            </div>

                            <div className="input-group">
                                <label htmlFor="cpf">CPF</label>
                                <InputMask
                                    id="cpf"
                                    mask="999.999.999-99"
                                    value={cpf}
                                    onChange={(e) => setCpf(e.target.value)}
                                    style={{
                                        marginBottom: '20px',
                                        textAlign: 'left',
                                        width: '33%',
                                        padding: '10px',
                                        border: '1px solid #ccc',
                                        bordeRadius: '15px',
                                        boxSizing: 'border-box',
                                        letterSpacing: 1
                                    }}
                                />

                            </div>

                            <div className="input-group">
                                <label htmlFor="identidade">Identidade</label>
                                <input
                                    id="identidade"
                                    type="text"
                                    value={identidade}
                                    onChange={(e) => setIdentidade(e.target.value.toUpperCase())}
                                    style={{
                                        marginBottom: '20px',
                                        textAlign: 'left',
                                        width: '33%',
                                        padding: '10px',
                                        border: '1px solid #ccc',
                                        bordeRadius: '4px',
                                        boxSizing: 'border-box'
                                    }}
                                />
                            </div>

                            <div className="input-group">
                                <label htmlFor="cnh">CNH</label>
                                <input
                                    id="cnh"
                                    type="text"
                                    value={cnh}
                                    onChange={(e) => setCnh(e.target.value.toUpperCase())}
                                    style={{
                                        marginBottom: '20px',
                                        textAlign: 'left',
                                        width: '33%',
                                        padding: '10px',
                                        border: '1px solid #ccc',
                                        bordeRadius: '15px',
                                        boxSizing: 'border-box',

                                    }}
                                />
                            </div>

                            <div className="input-group">
                                <label htmlFor="dataNascimento">Data de Nascimento</label>
                                <InputMask
                                    id="dataNascimento"
                                    mask="99/99/9999"
                                    value={dataNascimento}
                                    onChange={(e) => setDataNascimento(e.target.value)}
                                    style={{
                                        marginBottom: '20px',
                                        textAlign: 'left',
                                        width: '33%',
                                        padding: '10px',
                                        border: '1px solid #ccc',
                                        bordeRadius: '15px',
                                        boxSizing: 'border-box',
                                        letterSpacing: 1
                                    }}
                                />

                                <br></br>
                                {/* Campos dinâmicos para advogados */}
                                <hr className="linha-fraca" />
                                <br></br>
                                <center><label style={{ margin: 10 }}><b>Informações da Prisão</b></label></center>

                            </div>
                            <div className="input-group">
                                <label htmlFor="artigos">Data da Prisão</label>
                                <InputMask
                                    mask="99/99/9999"
                                    id="dataPrisao"
                                    type="text"
                                    value={dataPrisao}
                                    onChange={(e) => setDataPrisao(e.target.value.toUpperCase())}
                                    style={{
                                        marginBottom: '20px',
                                        textAlign: 'left',
                                        width: '33%',
                                        padding: '10px',
                                        border: '1px solid #ccc',
                                        bordeRadius: '15px',
                                        boxSizing: 'border-box',
                                        letterSpacing: 1
                                    }}
                                />
                            </div>
                            <div className="input-group">
                                <label htmlFor="artigos">Data da Progressão</label>
                                <InputMask
                                    mask="99/99/9999"
                                    id="dataProgressao"
                                    type="text"
                                    value={dataProgressao}
                                    onChange={(e) => setDataProgressao(e.target.value.toUpperCase())}
                                    style={{
                                        marginBottom: '20px',
                                        textAlign: 'left',
                                        width: '33%',
                                        padding: '10px',
                                        border: '1px solid #ccc',
                                        bordeRadius: '15px',
                                        boxSizing: 'border-box',
                                        letterSpacing: 1
                                    }}
                                />
                            </div>
                            <div className="input-group">
                                <label htmlFor="artigos">Fim da Prisão</label>
                                <InputMask
                                    mask="99/99/9999"
                                    id="dataFimPrisao"
                                    type="text"
                                    value={dataFimPrisao}
                                    onChange={(e) => setDataFimPrisao(e.target.value.toUpperCase())}
                                    style={{
                                        marginBottom: '20px',
                                        textAlign: 'left',
                                        width: '33%',
                                        padding: '10px',
                                        border: '1px solid #ccc',
                                        bordeRadius: '15px',
                                        boxSizing: 'border-box',
                                        letterSpacing: 1
                                    }}
                                />

                            </div>
                            <div className="input-group">
                                <label htmlFor="artigos">Localização Atual</label>
                                <select
        id="localizacao"
        value={localizacaoAtual}
        onChange={handleSelectChangeLocal}
      >
        <option value="">-- Selecione --</option>
        <option value="APAC">APAC - ASSOCIAÇÃO DE PROTEÇÃO E ASSISTÊNCIA AOS CONDENADOS</option>
        <option value="APACF">APACF - ASSOCIAÇÃO DE PROTEÇÃO E ASSISTÊNCIA AOS CONDENADOS DE CACHOEIRO</option>
        <option value="CASCUVV">CASCUVV - CASA DE CUSTÓDIA DE VILA VELHA</option>
        <option value="CDRL">CDRL - CENTRO DE DETENÇÃO E RESSOCIALIZAÇÃO DE LINHARES</option>
        <option value="CDPS">CDPS - CENTRO DE DETENÇÃO PROVISÓRIA DA SERRA</option>
        <option value="CDPA">CDPA - CENTRO DE DETENÇÃO PROVISÓRIA DE ARACRUZ</option>
        <option value="CDPCI">CDPCI - CENTRO DE DETENÇÃO PROVISÓRIA DE CACHOEIRO DO ITAPEMIRIM</option>
        <option value="CDPC">CDPC - CENTRO DE DETENÇÃO PROVISÓRIA DE CARIACICA</option>
        <option value="CDPCOL">CDPCOL - CENTRO DE DETENÇÃO PROVISÓRIA DE COLATINA</option>
        <option value="CDPG">CDPG - CENTRO DE DETENÇÃO PROVISÓRIA DE GUARAPARI</option>
        <option value="CDPM">CDPM - CENTRO DE DETENÇÃO PROVISÓRIA DE MARATAÍZES</option>
        <option value="CDPSDN">CDPSDN - CENTRO DE DETENÇÃO PROVISÓRIA DE SÃO DOMINGOS DO NORTE</option>
        <option value="CDPSM">CDPSM - CENTRO DE DETENÇÃO PROVISÓRIA DE SÃO MATEUS</option>
        <option value="CDPV II">CDPV II - CENTRO DE DETENÇÃO PROVISORIA DE VIANA 2</option>
        <option value="CDPVV">CDPVV - CENTRO DE DETENÇÃO PROVISÓRIA DE VILA VELHA</option>
        <option value="CDPFV">CDPFV - CENTRO DE DETENÇÃO PROVISÓRIA FEMININO DE VIANA</option>
        <option value="CDPFVV">CDPFVV - CENTRO DE DETENÇÃO PROVISÓRIA FEMININO DE VILA VELHA</option>
        <option value="CTV">CTV - CENTRO DE TRIAGEM DE VIANA</option>
        <option value="CPFCI">CPFCI - CENTRO PRISIONAL FEMININO DE CACHOEIRO DE ITAPEMIRIM</option>
        <option value="CPFC">CPFC - CENTRO PRISIONAL FEMININO DE CARIACICA</option>
        <option value="CPFCOL">CPFCOL - CENTRO PRISIONAL FEMININO DE COLATINA</option>
        <option value="PAES">PAES - PENITENCIÁRIA AGRÍCOLA DO ESPÍRITO SANTO</option>
        <option value="PSMA I">PSMA I - PENITENCIÁRIA DE SEGURANÇA MÁXIMA I</option>
        <option value="PSMA II">PSMA II - PENITENCIÁRIA DE SEGURANÇA MÁXIMA II</option>
        <option value="PSME I">PSME I - PENITENCIÁRIA DE SEGURANÇA MÉDIA  I</option>
        <option value="PSMECOL">PSMECOL - PENITENCIÁRIA DE SEGURANÇA MÉDIA DE COLATINA</option>
        <option value="PSME II">PSME II - PENITENCIÁRIA DE SEGURANÇA MÉDIA II</option>
        <option value="PEVV I">PEVV I - PENITENCIÁRIA ESTADUAL DE VILA VELHA I</option>
        <option value="PEVV II">PEVV II - PENITENCIÁRIA ESTADUAL DE VILA VELHA II</option>
        <option value="PEVV III">PEVV III - PENITENCIÁRIA ESTADUAL DE VILA VELHA III</option>
        <option value="PEVV IV">PEVV IV - PENITENCIÁRIA ESTADUAL DE VILA VELHA IV</option>
        <option value="PEVV V">PEVV V - PENITENCIÁRIA ESTADUAL DE VILA VELHA V</option>
        <option value="PEVV VI">PEVV VI - PENITENCIÁRIA ESTADUAL DE VILA VELHA VI</option>
        <option value="PEF">PEF - PENITENCIÁRIA ESTADUAL FEMININA</option>
        <option value="PFCG">PFCG - PENITENCIÁRIA FEDERAL DE CAMPO GRANDE</option>
        <option value="PFCAT">PFCAT - PENITENCIÁRIA FEDERAL DE CATANDUVAS</option>
        <option value="PFMOS">PFMOS - PENITENCIÁRIA FEDERAL DE MOSSORÓ</option>
        <option value="PFPV">PFPV - PENITENCIÁRIA FEDERAL DE PORTO VELHO</option>
        <option value="PFC">PFC - PENITENCIÁRIA FEMININA CARIACICA</option>
        <option value="PRBSF">PRBSF - PENITENCIÁRIA REGIONAL DE BARRA DE SÃO FRANCISCO</option>
        <option value="PRCI">PRCI - PENITENCIÁRIA REGIONAL DE CACHOEIRO DE ITAPEMIRIM</option>
        <option value="PRCOL">PRCOL - PENITENCIÁRIA REGIONAL DE COLATINA</option>
        <option value="PRL">PRL - PENITENCIÁRIA REGIONAL DE LINHARES</option>
        <option value="PRSM">PRSM - PENITENCIÁRIA REGIONAL DE SÃO MATEUS</option>
        <option value="PSC I">PSC I - PENITENCIÁRIA SEMIABERTA DE CARIACICA I</option>
        <option value="PSSM">PSSM - PENITENCIÁRIA SEMIABERTA DE SÃO MATEUS</option>
        <option value="PSVV">PSVV - PENITENCIÁRIA SEMIABERTA DE VILA VELHA</option>
        <option value="PSVV II">PSVV II - PENITENCIÁRIA SEMIABERTA DE VILA VELHA II</option>
        <option value="PSMCOL">PSMCOL - PENITENCIÁRIA SEMIABERTA MASCULINA DE COLATINA</option>
        <option value="QCGPMES">QCGPMES - QUARTEL DO COMANDO GERAL DA POLÍCIA MILITAR DO EST. DO ESPÍRITO SANTO</option>
        <option value="UCTP">UCTP - UNIDADE DE CUSTÓDIA E TRATAMENTO PSIQUIÁTRICO</option>
        <option value="USSP">USSP - UNIDADE DE SAÚDE DO SISTEMA PENITENCIÁRIO</option>
       
      </select>
                            </div>
                            <div className="input-group">
                                <label htmlFor="artigos">Área de Atuação Criminal</label>
                                <input
                                    id="Atuacao"
                                    type="text"
                                    value={areaAtuacao}
                                    onChange={(e) => setAreaAtuacao(e.target.value.toUpperCase())}
                                />
                            </div>
                            <div className="input-group">
                                <label htmlFor="artigos">Artigos</label>
                                <input
                                    id="artigos"
                                    type="text"
                                    value={artigos}
                                    onChange={(e) => setArtigos(e.target.value.toUpperCase())}
                                />
                            </div>
                            <br />
                            <hr className="linha-fraca" />
                            <br />
                            <div className="input-group">
                                <center><label style={{ margin: 10 }}><b>Advogados</b></label></center>
                                {advogados.map((advogado, index) => (
                                    <div key={index} className="advogado-input-group">

                                        <input
                                            type="text"
                                            placeholder="Nome do Advogado"
                                            value={advogado.nome}
                                            onChange={(e) => handleAdvogadoChange(index, 'nome', e.target.value.toUpperCase())}
                                            required
                                        />
                                        <input
                                            type="text"
                                            placeholder="OAB"
                                            value={advogado.oab}
                                            onChange={(e) => handleAdvogadoChange(index, 'oab', e.target.value.toUpperCase())}
                                            required
                                        />
                                        {index > 0 && ( // Exibe o botão de remover apenas para advogados a partir do segundo
                                            <button
                                                type="button"
                                                onClick={() => handleRemoveAdvogado(index)}
                                                className="remove-advogado-button"
                                            >
                                                <LiaTrashSolid />
                                            </button>
                                        )}
                                    </div>
                                ))}
                                <center><button type="button" onClick={handleAddAdvogado} className="add-advogado-button">
                                    + Adicionar
                                </button></center>
                            </div>
                            <br></br>
                            {/* Campos dinâmicos para advogados */}
                            <hr className="linha-fraca" />
                            <br></br>
                            <div className="input-group">
                                <center><label style={{ margin: 10 }}><b>Presos no Mesmo Processo</b></label></center>
                                {presos.map((presos, index) => (
                                    <div key={index} className="preso-input-group">
                                        <input
                                            type="text"
                                            placeholder="Nome do Preso"
                                            value={presos.nome}
                                            onChange={(e) => handlePresoChange(index, 'nome', e.target.value.toUpperCase())}

                                        />
                                        <input
                                            type="text"
                                            placeholder="Número do Processo"
                                            value={presos.processo}
                                            onChange={(e) => handlePresoChange(index, 'processo', e.target.value.toUpperCase())}

                                        />
                                        <input
                                            type="text"
                                            placeholder="Infopen"
                                            value={presos.infopen}
                                            onChange={(e) => handlePresoChange(index, 'infopen', e.target.value.toUpperCase())}

                                        />
                                        {index > 0 && (

                                            <button
                                                type="button"
                                                onClick={() => handleRemovePreso(index)}
                                                className="remove-advogado-button"
                                            >
                                                <LiaTrashSolid />
                                            </button>
                                        )}
                                    </div>
                                ))}
                                <center><button type="button" onClick={handleAddPreso} className="add-advogado-button">
                                    + Adicionar
                                </button></center>
                            </div>
                            <br></br>
                            {/* Campos dinâmicos para advogados */}
                            <hr className="linha-fraca" />
                            <br></br>
                            <div className="input-group">
                                <center><label style={{ margin: 10 }}><b>Vínculos</b></label></center>
                                {pessoasEnvolvimento.map((pessoasEnvolvimento, index) => (
                                    <div key={index} className="preso-input-group">
                                        <input
                                            type="text"
                                            placeholder="Nome"
                                            value={pessoasEnvolvimento.nome}
                                            onChange={(e) => handlePessoasEnvolvimentoChange(index, 'nome', e.target.value.toUpperCase())}

                                        />
                                        <input
                                            type="text"
                                            placeholder="Relação"
                                            value={pessoasEnvolvimento.relacao}
                                            onChange={(e) => handlePessoasEnvolvimentoChange(index, 'relacao', e.target.value.toUpperCase())}

                                        />
                                        <input
                                            type="text"
                                            placeholder="Complemento"
                                            value={pessoasEnvolvimento.obs}
                                            onChange={(e) => handlePessoasEnvolvimentoChange(index, 'obs', e.target.value.toUpperCase())}

                                        />
                                        {index > 0 && (
                                            <button
                                                type="button"
                                                onClick={() => handleRemovePessoasEnvolvimento(index)}
                                                className="remove-advogado-button"
                                            >
                                                <LiaTrashSolid />
                                            </button>

                                        )}
                                    </div>
                                ))}
                                <center><button type="button" onClick={handleAddPessoasEnvolvimento} className="add-advogado-button">
                                    + Adicionar
                                </button></center>
                            </div>

                            <br></br>
                            <hr className="linha-fraca" />
                            <br></br>
                            <div className="input-group">
                                <center><label style={{ margin: 10 }}><b>Telefones</b></label></center>
                                {telefones.map((telefones, index) => (
                                    <div key={index} className="preso-input-group">
                                        <InputMask
                                            type="text"
                                            mask={"(99)99999-9999"}
                                            placeholder="Telefone"
                                            value={telefones.telefone}
                                            onChange={(e) => handleTelefoneChange(index, 'telefone', e.target.value.toUpperCase())}

                                        />
                                        <input
                                            type="text"
                                            placeholder="Observação"
                                            value={telefones.obs}
                                            onChange={(e) => handleTelefoneChange(index, 'obs', e.target.value.toUpperCase())}

                                        />

                                        {index > 0 && (



                                            <button
                                                type="button"
                                                onClick={() => handleRemoveTelefones(index)}
                                                className="remove-advogado-button"
                                            >
                                                <LiaTrashSolid />
                                            </button>
                                        )}
                                    </div>
                                ))}
                                <center> <button type="button" onClick={handleAddTelefone} className="add-advogado-button">
                                    + Adicionar
                                </button></center>
                            </div>
                            <br></br>
                            <hr className="linha-fraca" />
                            <br></br>
                            <div className="input-group">
                                <center><label style={{ margin: 10 }}><b>Redes Socias</b></label></center>


                                <br></br>
                                <div class="input-container">
                                    <div class="icon-container">
                                        <FaFacebook />
                                    </div>
                                    <input
                                        type="text"
                                        placeholder="Facebook"
                                        value={facebook}
                                        onChange={(e) => setFacebook(e.target.value.toUpperCase())}

                                    />
                                </div>
                                <br></br>
                                <div class="input-container">
                                    <div class="icon-container">
                                        <RiInstagramFill />
                                    </div>
                                    <input
                                        type="text"
                                        placeholder="instagram"
                                        value={instagram}

                                        onChange={(e) => setInstagram(e.target.value.toUpperCase())}
                                    />
                                </div>
                                <br></br>
                                <div class="input-container">
                                    <div class="icon-container">
                                        <FaSquareTwitter />
                                    </div>
                                    <input
                                        type="text"
                                        placeholder="Twitter"
                                        value={twitter}

                                        onChange={(e) => setTwitter(e.target.value.toUpperCase())}
                                    />
                                </div>
                                <br></br>
                                <div class="input-container">
                                    <div class="icon-container">

                                        <SiTiktok />

                                    </div>
                                    <input
                                        type="text"
                                        placeholder="Tiktok"
                                        value={tiktok}

                                        onChange={(e) => setTiktok(e.target.value.toUpperCase())} />
                                </div>





                            </div>
                            <br />

                            <hr className="linha-fraca" />
                            
                            <center><label style={{ margin: 10, fontSize: 14 }}><b>Estimativa de Periculosidade</b></label></center>
                            <br></br>
                            <div className='input-group'>
                                <label htmlFor="galeria-monitorada">Galeria Monitorada?</label>
                                <select
                                    id="galeria-monitorada"
                                    value={galeriaMonitorada}
                                    onChange={handleSelectChange}
                                >
                                    <option value="">Selecione</option>
                                    <option value="SIM">Sim</option>
                                    <option value="NAO">Não</option>
                                </select>

                                {/* Exemplo de como você pode usar o valor selecionado */}

                            </div>
                            <div className='input-group'>
                                <label htmlFor="faccao-criminosa">Preso Faccionado?</label>
                                <select
                                    id="faccao-criminosa"
                                    value={faccaoCriminosa}
                                    onChange={handleSelectChangeFaccao}
                                >
                                    <option value="">Não Faccionado</option>
                                    <option value="CV">CV</option>
                                    <option value="PCC">PCC</option>
                                    <option value="PCV">PCV</option>
                                    <option value="TCP">TCP</option>
                                    <option value="ADA">ADA</option>
                                    <option value="AFC">AFC</option>
                                    <option value="OUTRO">OUTRO</option>
                                </select>

                                {/* Exemplo de como você pode usar o valor selecionado */}

                            </div>

                            {faccaoCriminosa != '' && <div className="input-group">
                                <label htmlFor="artigos">Posição Hierárquica na Facção</label>
                                <select
                                    id="faccao-posicao"
                                    value={posicao}
                                    onChange={handleSelectChangePosicao}
                                >
                                    <option value="">Selecione</option>
                                    <option value="JET">JET</option>
                                    <option value="SALVEIRO">SALVEIRO</option>
                                    <option value="DISCIPLINA">DISCIPLINA</option>
                                    <option value="GALERIA">GALERIA</option>
                                    <option value="PREDIO">PRÉDIO</option>

                                </select>
                            </div>}



                            {/* iNÍCIO DO PAINEL DE CONFIGURAÇÃO DO CALCULO AUTOMATICO DE PERICULOSIDADE */}

                            <br></br>
                            {/* Campos dinâmicos para advogados */}


                            <div className='input-group'>

                                <br />
                                <div className="checkbox-grid-container">

                                    <div className="checkbox-grid">
                                        {Object.keys(selectedValues).map((item) => (
                                            <label key={item} className="checkbox-item">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedValues[item] !== 0}
                                                    onChange={() => handleCheckboxChange(item)}
                                                />
                                                {item}
                                            </label>
                                        ))}
                                    </div>
                                    <br></br>
                                    {/* Campos dinâmicos para advogados */}
                                    <hr className="linha-fraca" />
                                    <br></br>
                                    <center><span className="titulo" style={{ fontSize: 16 }}>SCORE DE PERICULOSIDADE ({score}) </span></center>
                                    <br></br><br></br>
                                    <div
                                        className="dynamic-div"
                                        style={{ backgroundColor: getBackgroundColor(score) }}
                                    >
                                        {score <= 50 && <b style={{ color: 'green' }}>Baixíssima</b>}
                                        {score > 50 && score <= 100 && <b>Baixa</b>}
                                        {score > 100 && score <= 150 && <b>Média</b>}
                                        {score > 150 && score <= 200 && <b>Alta</b>}
                                        {score > 200 && <b style={{ color: 'white' }}>Altíssima</b>}
                                    </div>

                                </div>

                            </div>
                            <br></br><br></br>
                            <div style={{ justifyContent: 'center', display: 'flex' }}>
                                <button type="submit" className="register-button">GRAVAR</button>
                            </div>
                        </form>
                        <br></br>
                        <br></br>
                        <br></br>
                    </div>
<br></br>
                        <br></br>
                        <br></br>
                </div> 
            </div>
           
        </div>
    );
};

export default Editar;
