import React, { useState, useEffect } from 'react';
import './ModalAguia.css'; // Estilos do modal
import loadingAnimation from '../assets/animation/loading.json'; // Arquivo JSON da animação
import Lottie from 'react-lottie';
import logo from '../assets/img/logo2.png';


const ModalPreso = ({ isOpen, onClose, presoId }) => {
  const [dadosPreso, setDadosPreso] = useState(null);
  const [camposCadastraisComuns, setCamposCadastraisComuns] = useState([]);
  const [camposAliadosComuns, setCamposAliadosComuns] = useState([]);
  const [camposAdvogadosComuns, setCamposAdvogadosComuns] = useState([]);
  const [loading, setLoading] = useState(true)
  const [contadorLoading, setContadorLoading] = useState(0)
  

  useEffect(() => {
    if (contadorLoading === 3) {
      setLoading(false);
    }
  }, [contadorLoading]);
  

  useEffect(() => {
    if (isOpen && presoId) {
      const buscarDadosPreso = async () => {
        try {
          const response = await fetch(`https://devkaioribeiro.com/apidipp/list_preso_id.php?id=${presoId}`);
          const data = await response.json();

          if (data.error) {
            console.error('Erro:', data.error);
          } else {
            setDadosPreso(data);
            buscarCamposCadastraisComuns();
            buscarAlidadosComuns();
            setContadorLoading(prev => prev + 1);
          }
        } catch (error) {
          console.error('Erro ao buscar dados:', error);
        }
      };

      buscarDadosPreso();
    }
  }, [isOpen, presoId]);

  useEffect(() => {
    if (dadosPreso) {
      buscarAvogadosComuns();
    }
  }, [dadosPreso]);

  const buscarCamposCadastraisComuns = async () => {
    try {
      const response = await fetch(`https://devkaioribeiro.com/apidipp/api_aguia/buscar_campos_cadastrais_comuns.php?id=${presoId}`);
      const text = await response.text();
      console.log("Resposta bruta da API buscar_campos_comuns:", text);
      setContadorLoading(prev => prev + 1);

      try {
        const camposComunsData = JSON.parse(text);
        if (Array.isArray(camposComunsData)) {
          setCamposCadastraisComuns(camposComunsData);
        } else {
          setCamposCadastraisComuns([]);
        }
      } catch (parseError) {
        console.error('Erro ao fazer parse do JSON:', parseError);
      }
    } catch (error) {
      console.error('Erro ao buscar campos comuns:', error);
      setCamposCadastraisComuns([]);
    }
  };

  const buscarAlidadosComuns = async () => {
    try {
      const response = await fetch(`https://devkaioribeiro.com/apidipp/api_aguia/buscar_aliados_local.php?id=${presoId}`);
      const text = await response.text();
      console.log("Resposta bruta da API buscar_campos_comuns:", text);
      setContadorLoading(prev => prev + 1);

      try {
        const camposComunsData = JSON.parse(text);
        if (Array.isArray(camposComunsData)) {
          setCamposAliadosComuns(camposComunsData);
        } else {
          setCamposAliadosComuns([]);
        }
      } catch (parseError) {
        console.error('Erro ao fazer parse do JSON:', parseError);
      }
    } catch (error) {
      console.error('Erro ao buscar campos comuns:', error);
      setCamposAliadosComuns([]);
    }
  };

  const buscarAvogadosComuns = async () => {
    if (dadosPreso && dadosPreso.advogados) {
      try {
        const advogados = JSON.parse(dadosPreso.advogados);
        if (Array.isArray(advogados) && advogados.length > 0) {
          const advogadosJson = JSON.stringify(advogados);
          const response = await fetch(`https://devkaioribeiro.com/apidipp/api_aguia/buscar_advogados_comuns.php?advogados=${encodeURIComponent(advogadosJson)}&id=${presoId}`);
          const text = await response.text();
          console.log("Resposta bruta da API buscar_advogados_comuns:", text);
          setContadorLoading(prev => prev + 1);

          try {
            const camposComunsData = JSON.parse(text);
            if (Array.isArray(camposComunsData.data)) {
              setCamposAdvogadosComuns(camposComunsData.data);
            } else {
              setCamposAdvogadosComuns([]);
            }
          } catch (parseError) {
            console.error('Erro ao fazer parse do JSON:', parseError);
          }
        } else {
          setCamposAdvogadosComuns([]);
        }
      } catch (error) {
        setCamposAdvogadosComuns([]);
      }
    } else {
      setCamposAdvogadosComuns([]);
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };
  const getBackgroundColor = (totalValue) => {
    const limits = [
      { max: 50, color: '#00FF00' },
      { max: 100, color: '#FFFF00' },
      { max: 150, color: '#FFA500' },
      { max: 200, color: '#ff8100' },
      { max: 300, color: '#FF0000' }
    ];
  
    const interpolateColor = (color1, color2, factor) => {
      const hexToRgb = (hex) => hex.match(/\w\w/g).map(x => parseInt(x, 16));
      const rgbToHex = (rgb) => `#${rgb.map(x => x.toString(16).padStart(2, '0')).join('')}`;
  
      const [r1, g1, b1] = hexToRgb(color1);
      const [r2, g2, b2] = hexToRgb(color2);
  
      const r = Math.round(r1 + (r2 - r1) * factor);
      const g = Math.round(g1 + (g2 - g1) * factor);
      const b = Math.round(b1 + (b2 - b1) * factor);
  
      return rgbToHex([r, g, b]);
    };
  
    for (let i = 0; i < limits.length - 1; i++) {
      if (totalValue <= limits[i].max) {
        const rangeStart = i === 0 ? 0 : limits[i - 1].max;
        const rangeEnd = limits[i].max;
        const factor = (totalValue - rangeStart) / (rangeEnd - rangeStart);
        const colorStart = i === 0 ? limits[0].color : limits[i - 1].color;
        const colorEnd = limits[i].color;
        return interpolateColor(colorStart, colorEnd, factor);
      }
    }
    return limits[limits.length - 1].color;
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
  };

  return (
    isOpen && (
      <div className="modal-overlay" onClick={handleOverlayClick}>
         {loading && (
              <div className="loading-container">
                <Lottie options={defaultOptions} animationData={loadingAnimation} loop={true} />
              </div>
            )}
       {!loading && ( <div className="modal-content" >
         
       <center> <div className="header-container">
  <img src={logo} height={70} alt="Logo" style={{marginRight:8, opacity:0.8}} />
  <div className="title-container">
    <h1 className="h1">HUNTER</h1>
    <h2 className="subtitle">SISTEMA AVANÇADO DE ANÁLISE</h2> {/* Subtítulo abaixo do título */}
  </div>
</div></center> 
          <br></br>
          <br></br>
          <div className="aguia-preso-detalhe">
  <div className="aguia-card">
    {!loading && dadosPreso?.imagem && (
      <img
        src={`https://devkaioribeiro.com/apidipp/${dadosPreso.imagem}`}
        alt="Imagem do Preso"
        className="aguia-image-preview"
        style={{
          borderBottom: `12px solid ${getBackgroundColor(dadosPreso.score)}`,
        }}
      />
    )}

    <div className="aguia-info-section">
      {!loading && dadosPreso && (
        <>
          <label htmlFor="nome">Alvo Analisado</label>
          <h2>{`${dadosPreso.nome} - ${dadosPreso.alcunha}`}</h2>

          <label htmlFor="faccao">Facção</label>
          <h2>
            {dadosPreso.faccaoCriminosa.toUpperCase()} (
            {dadosPreso.posicao.toUpperCase()})
          </h2>

          <div className="aguia-detail-row">
            <div>
              <label htmlFor="score">Score</label>
              <h2>{dadosPreso.score}</h2>
            </div>
            <div>
              <label htmlFor="localizacao">Localização</label>
              <h2>{dadosPreso.localizacaoAtual.toUpperCase()}</h2>
            </div>
            <div>
              <label htmlFor="infopen">Infopen</label>
              <h2>{dadosPreso.infopen}</h2>
            </div>
          </div>
        </>
      )}
    </div>
  </div>
</div>
<hr class="hr-dotted"/>


          <div className="campos-comuns">
            {camposCadastraisComuns.length > 0 && loading == false && <center><h3 className="subtitle2">PRESOS COM MESMO NOME OU ALCUNHA ({camposCadastraisComuns.length})</h3></center>}
            {loading == false && camposCadastraisComuns.map((preso) => (
              <><div key={preso.id} className="aguia-card-presos">
                <img
                  className="aguia-card-img"
                  src={`https://devkaioribeiro.com/apidipp/${preso.imagem}`}
                  alt={preso.nome}
                />
                <div className="aguia-card-detalhes">
                  <div><strong>Nome:</strong> {preso.nome} ({preso.score})</div>
                  <div><strong>Infopen:</strong> {preso.infopen}</div>
                  <div><strong>Localização:</strong> {preso.localizacaoAtual}</div>
                  <div><strong>Facção:</strong> {preso.faccaoCriminosa} {preso.posicao != "" && (`(${preso.posicao})`)}</div>
                </div>
              </div><hr class="hr-dotted"/>
</>
            ))}
          </div>
          <div className="aguia-campos-comuns">
            {camposAliadosComuns.length > 0 && loading == false && <center><h3 className='subtitle2'>PRESOS DA MESMA FACÇÃO ({dadosPreso.localizacaoAtual}) ({camposAliadosComuns.length})</h3></center>}
            {loading == false && camposAliadosComuns.map((preso) => (
             <> <div key={preso.id} className="aguia-card-presos">
                <img
                  className="aguia-card-img"
                  src={`https://devkaioribeiro.com/apidipp/${preso.imagem}`}
                  alt={preso.nome}
                />
                <div className="aguia-card-detalhes">
                  <div><strong>Nome:</strong> {preso.nome}</div>
                  <div><strong>Infopen:</strong> {preso.infopen}</div>
                  <div><strong>Score:</strong> {preso.score}</div>
                </div>
              </div><hr class="hr-dotted"/>

              </>
            ))}
          </div>

          {camposAdvogadosComuns.length === 0 && 
 camposCadastraisComuns.length === 0 && 
 camposAliadosComuns.length === 0 && 
 <div style={{
   display: 'flex', 
   justifyContent: 'center', 
   alignItems: 'center', 
   height: '100%', 
   textAlign: 'center'
 }}>
   <h3 className='subtitle2'>SEM OCORRÊNCIAS</h3>
 </div>}
  
  <div className="aguia-campos-comuns">
            {camposAdvogadosComuns.length > 0 && <center><h3 className='subtitle2'>PRESOS COM O MESMO ADVOGADO ({camposAdvogadosComuns.length})</h3></center>}
            {camposAdvogadosComuns.map((preso) => (
             <> <div key={preso.id} className="aguia-card-presos">
                <img
                  className="aguia-card-img"
                  src={`https://devkaioribeiro.com/apidipp/${preso.imagem}`}
                  alt={preso.nome}
                />
                <div className="aguia-card-detalhes">
                  <div><strong>Nome:</strong> {preso.nome}</div>
                  <div><strong>Infopen:</strong> {preso.infopen}</div>
                  <div><strong>Score:</strong> {preso.score}</div>
                </div>
              </div><hr class="hr-dotted"/>
</>
            ))}
          </div>
        </div>)}
       
      </div>
      
    )
  );
};

export default ModalPreso;
