import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Pie } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import './Dashboard.css';
import SkeletonLoader from '../components/SkeletonLoader';

Chart.register(CategoryScale, LinearScale, ArcElement, Title, Tooltip, Legend, ChartDataLabels);

const Dashboard = () => {
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(true);
  const [localizacaoAtual, setLocalizacaoAtual] = useState('');

  const handleSelectChangeLocal = (event) => {
    setLocalizacaoAtual(event.target.value);
  };

  const fetchDataByLocation = async (location) => {
    try {
      const response = await axios.get(`https://devkaioribeiro.com/apidipp/list_presos_local.php?localizacao=${location}`);
      setDados(response.data.length > 0 ? response.data : []); // Atualiza os dados com a resposta da API
      setLoading(false);
    } catch (error) {
      console.error('Erro ao buscar dados por localização:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
  
        if (localizacaoAtual !== '') {
          await fetchDataByLocation(localizacaoAtual);
        } else {
          const response = await axios.get('https://devkaioribeiro.com/apidipp/list_presos.php');
          setDados(response.data.length > 0 ? response.data : []);
        }
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData(); // Chama a função fetchData
  
  }, [localizacaoAtual]);

  const groupData = (field) => {
    const group = {};
    dados.forEach(item => {
      const key = item[field];
      if (group[key]) {
        group[key] += 1;
      } else {
        group[key] = 1;
      }
    });

    // Se não houver dados, retorne valores zerados
    if (Object.keys(group).length === 0) {
      group['Sem Dados'] = 0;
    }
    return group;
  };

  const groupScoreData = () => {
    const ranges = {
      '0 a 50': 0,
      '51 a 100': 0,
      '101 a 150': 0,
      '151 a 200': 0,
      'Acima de 200': 0,
    };

    dados.forEach(item => {
      const score = item.score;
      if (score <= 50) {
        ranges['0 a 50'] += 1;
      } else if (score <= 100) {
        ranges['51 a 100'] += 1;
      } else if (score <= 150) {
        ranges['101 a 150'] += 1;
      } else if (score <= 200) {
        ranges['151 a 200'] += 1;
      } else {
        ranges['Acima de 200'] += 1;
      }
    });

    return ranges;
  };

  const preparePieData = (field) => {
    const groupedData = groupData(field);
    const labels = Object.keys(groupedData);
    const values = Object.values(groupedData);

    // Se não houver valores, exiba 0
    const chartData = values.length > 0 ? values : [0];

    return {
      labels: labels.length > 0 ? labels : ['Sem Dados'],
      datasets: [
        {
          label: `Distribuição por ${field}`,
          data: chartData,
          backgroundColor: [
            '#6d94b8',  // Azul mais escuro
            'rgba(204, 127, 51, 0.6)',  // Laranja mais escuro
            'rgba(122, 82, 204, 0.6)',  // Roxo mais escuro
            'rgba(204, 164, 69, 0.6)',  // Amarelo mais escuro
            'rgba(204, 79, 105, 0.6)',  // Vermelho mais escuro
          ],
          hoverBackgroundColor: [
            'rgba(60, 154, 154, 0.8)',  // Azul mais escuro no hover
            'rgba(204, 127, 51, 0.8)',  // Laranja mais escuro no hover
            'rgba(122, 82, 204, 0.8)',  // Roxo mais escuro no hover
            'rgba(204, 164, 69, 0.8)',  // Amarelo mais escuro no hover
            'rgba(204, 79, 105, 0.8)',  // Vermelho mais escuro no hover
          ],
          borderWidth: 1,
        },
      ],
    };
  };

  const prepareScorePieData = () => {
    const groupedData = groupScoreData(); // Chama a função de agrupamento por faixas de score
    const labels = Object.keys(groupedData); // Obtém os labels das faixas de score
    const values = Object.values(groupedData); // Obtém os valores correspondentes às faixas

    // Se não houver valores, exiba 0
    const chartData = values.length > 0 ? values : [0];

    return {
      labels: labels.length > 0 ? labels : ['Sem Dados'],
      datasets: [
        {
          label: 'Distribuição por Faixa de Score',
          data: chartData,
          backgroundColor: [
            '#6d94b8',  // Azul mais escuro
            'rgba(204, 127, 51, 0.6)',  // Laranja mais escuro
            'rgba(122, 82, 204, 0.6)',  // Roxo mais escuro
            'rgba(204, 164, 69, 0.6)',  // Amarelo mais escuro
            'rgba(204, 79, 105, 0.6)',  // Vermelho mais escuro
          ],
          hoverBackgroundColor: [
            'rgba(60, 154, 154, 0.8)',  // Azul mais escuro no hover
            'rgba(204, 127, 51, 0.8)',  // Laranja mais escuro no hover
            'rgba(122, 82, 204, 0.8)',  // Roxo mais escuro no hover
            'rgba(204, 164, 69, 0.8)',  // Amarelo mais escuro no hover
            'rgba(204, 79, 105, 0.8)',  // Vermelho mais escuro no hover
          ],

          borderWidth: 1,
        },
      ],
    };
  };

  if (loading) {
    return <SkeletonLoader />; // Exibindo Skeleton durante o carregamento
  }

  return (
    <div className="dashboard-container"><br></br><br></br>
    <h1>ESTATÍSTICA</h1>  
    <br></br><br></br>
      <select
        id="localizacao"
        value={localizacaoAtual}
        onChange={handleSelectChangeLocal}
        style={{
          width: '100%',                // Largura total
          padding: '12px',               // Espaçamento interno
          fontSize: '16px',              // Tamanho da fonte
          borderRadius: '5px',           // Borda arredondada
          border: '1px solid #ccc',      // Cor da borda
          backgroundColor: '#f9f9f9',    // Cor de fundo
          color: '#333',                 // Cor do texto
          boxShadow: '0 2px 5px rgba(0,0,0,0.1)',  // Sombra suave
          appearance: 'none',            // Remove a aparência padrão do navegador
          outline: 'none',               // Remove o contorno de foco padrão
          cursor: 'pointer',             // Muda o cursor para mão ao passar
        }}
      >
       <option value="">GERAL</option>
        <option value="APAC">APAC - ASSOCIAÇÃO DE PROTEÇÃO E ASSISTÊNCIA AOS CONDENADOS</option>
        <option value="APACF">APACF - ASSOCIAÇÃO DE PROTEÇÃO E ASSISTÊNCIA AOS CONDENADOS DE CACHOEIRO</option>
        <option value="CASCUVV">CASCUVV - CASA DE CUSTÓDIA DE VILA VELHA</option>
        <option value="CDRL">CDRL - CENTRO DE DETENÇÃO E RESSOCIALIZAÇÃO DE LINHARES</option>
        <option value="CDPS">CDPS - CENTRO DE DETENÇÃO PROVISÓRIA DA SERRA</option>
        <option value="CDPA">CDPA - CENTRO DE DETENÇÃO PROVISÓRIA DE ARACRUZ</option>
        <option value="CDPCI">CDPCI - CENTRO DE DETENÇÃO PROVISÓRIA DE CACHOEIRO DO ITAPEMIRIM</option>
        <option value="CDPC">CDPC - CENTRO DE DETENÇÃO PROVISÓRIA DE CARIACICA</option>
        <option value="CDPCOL">CDPCOL - CENTRO DE DETENÇÃO PROVISÓRIA DE COLATINA</option>
        <option value="CDPG">CDPG - CENTRO DE DETENÇÃO PROVISÓRIA DE GUARAPARI</option>
        <option value="CDPM">CDPM - CENTRO DE DETENÇÃO PROVISÓRIA DE MARATAÍZES</option>
        <option value="CDPSDN">CDPSDN - CENTRO DE DETENÇÃO PROVISÓRIA DE SÃO DOMINGOS DO NORTE</option>
        <option value="CDPSM">CDPSM - CENTRO DE DETENÇÃO PROVISÓRIA DE SÃO MATEUS</option>
        <option value="CDPV II">CDPV II - CENTRO DE DETENÇÃO PROVISORIA DE VIANA 2</option>
        <option value="CDPVV">CDPVV - CENTRO DE DETENÇÃO PROVISÓRIA DE VILA VELHA</option>
        <option value="CDPFV">CDPFV - CENTRO DE DETENÇÃO PROVISÓRIA FEMININO DE VIANA</option>
        <option value="CDPFVV">CDPFVV - CENTRO DE DETENÇÃO PROVISÓRIA FEMININO DE VILA VELHA</option>
        <option value="CTV">CTV - CENTRO DE TRIAGEM DE VIANA</option>
        <option value="CPFCI">CPFCI - CENTRO PRISIONAL FEMININO DE CACHOEIRO DE ITAPEMIRIM</option>
        <option value="CPFC">CPFC - CENTRO PRISIONAL FEMININO DE CARIACICA</option>
        <option value="CPFCOL">CPFCOL - CENTRO PRISIONAL FEMININO DE COLATINA</option>
        <option value="PAES">PAES - PENITENCIÁRIA AGRÍCOLA DO ESPÍRITO SANTO</option>
        <option value="PSMA I">PSMA I - PENITENCIÁRIA DE SEGURANÇA MÁXIMA I</option>
        <option value="PSMA II">PSMA II - PENITENCIÁRIA DE SEGURANÇA MÁXIMA II</option>
        <option value="PSME I">PSME I - PENITENCIÁRIA DE SEGURANÇA MÉDIA  I</option>
        <option value="PSMECOL">PSMECOL - PENITENCIÁRIA DE SEGURANÇA MÉDIA DE COLATINA</option>
        <option value="PSME II">PSME II - PENITENCIÁRIA DE SEGURANÇA MÉDIA II</option>
        <option value="PEVV I">PEVV I - PENITENCIÁRIA ESTADUAL DE VILA VELHA I</option>
        <option value="PEVV II">PEVV II - PENITENCIÁRIA ESTADUAL DE VILA VELHA II</option>
        <option value="PEVV III">PEVV III - PENITENCIÁRIA ESTADUAL DE VILA VELHA III</option>
        <option value="PEVV IV">PEVV IV - PENITENCIÁRIA ESTADUAL DE VILA VELHA IV</option>
        <option value="PEVV V">PEVV V - PENITENCIÁRIA ESTADUAL DE VILA VELHA V</option>
        <option value="PEVV VI">PEVV VI - PENITENCIÁRIA ESTADUAL DE VILA VELHA VI</option>
        <option value="PEF">PEF - PENITENCIÁRIA ESTADUAL FEMININA</option>
        <option value="PFCG">PFCG - PENITENCIÁRIA FEDERAL DE CAMPO GRANDE</option>
        <option value="PFCAT">PFCAT - PENITENCIÁRIA FEDERAL DE CATANDUVAS</option>
        <option value="PFMOS">PFMOS - PENITENCIÁRIA FEDERAL DE MOSSORÓ</option>
        <option value="PFPV">PFPV - PENITENCIÁRIA FEDERAL DE PORTO VELHO</option>
        <option value="PFC">PFC - PENITENCIÁRIA FEMININA CARIACICA</option>
        <option value="PRBSF">PRBSF - PENITENCIÁRIA REGIONAL DE BARRA DE SÃO FRANCISCO</option>
        <option value="PRCI">PRCI - PENITENCIÁRIA REGIONAL DE CACHOEIRO DE ITAPEMIRIM</option>
        <option value="PRCOL">PRCOL - PENITENCIÁRIA REGIONAL DE COLATINA</option>
        <option value="PRL">PRL - PENITENCIÁRIA REGIONAL DE LINHARES</option>
        <option value="PRSM">PRSM - PENITENCIÁRIA REGIONAL DE SÃO MATEUS</option>
        <option value="PSC I">PSC I - PENITENCIÁRIA SEMIABERTA DE CARIACICA I</option>
        <option value="PSSM">PSSM - PENITENCIÁRIA SEMIABERTA DE SÃO MATEUS</option>
        <option value="PSVV">PSVV - PENITENCIÁRIA SEMIABERTA DE VILA VELHA</option>
        <option value="PSVV II">PSVV II - PENITENCIÁRIA SEMIABERTA DE VILA VELHA II</option>
        <option value="PSMCOL">PSMCOL - PENITENCIÁRIA SEMIABERTA MASCULINA DE COLATINA</option>
        <option value="QCGPMES">QCGPMES - QUARTEL DO COMANDO GERAL DA POLÍCIA MILITAR DO EST. DO ESPÍRITO SANTO</option>
        <option value="UCTP">UCTP - UNIDADE DE CUSTÓDIA E TRATAMENTO PSIQUIÁTRICO</option>
        <option value="USSP">USSP - UNIDADE DE SAÚDE DO SISTEMA PENITENCIÁRIO</option>
        {/* Outras opções de localização */}
      </select>
      <br></br><br></br><br></br>
      <br></br>
      <div className="charts-container">
        {/* Gráfico de Pizza de Facções Criminosas */}
        <div className="chart">
          <Pie
            data={preparePieData('faccaoCriminosa')}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'top',
                  labels: {
                    font: {
                      size: 16,
                      family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
                    },
                    color: '#333',
                  },
                },
                title: {
                  display: true,
                  text: `Distribuição por Facções Criminosas (${dados.length})`,
                  font: {
                    size: 20,
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                    weight: 'bold',
                  },
                  color: '#333',
                },
                datalabels: {
                  color: '#fff',
                  font: {
                    size: 14,
                  },
                  formatter: (value, context) => {
                    const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
                    const percentage = ((value / total) * 100).toFixed(1);

                    return percentage > 0.1 ? `${percentage}%` : null;
                  },
                },
              },
              animation: {
                animateScale: true,
                animateRotate: true,
              },
            }}
            height={320}
            width={400}
          />
        </div>

        {/* Gráfico de Pizza de Localização Atual */}
        <div className="chart">
          { localizacaoAtual === '' && 
            <Pie
              data={preparePieData('localizacaoAtual')}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'top',
                    labels: {
                      font: {
                        size: 16,
                        family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
                      },
                      color: '#333',
                    },
                  },
                  title: {
                    display: true,
                    text: `Distribuição por Localização Atual  (${dados.length})`,
                    font: {
                      size: 20,
                      family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                      weight: 'bold',
                    },
                    color: '#333',
                  },
                  datalabels: {
                    color: '#fff',
                    font: {
                      size: 14,
                    },
                    formatter: (value, context) => {
                      const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
                      const percentage = ((value / total) * 100).toFixed(1);
                      return percentage > 0.1 ? `${percentage}%` : null;
                    },
                  },
                },
                animation: {
                  animateScale: true,
                  animateRotate: true,
                },
              }}
              height={320}
              width={400}
            />
          }
          { localizacaoAtual !== '' && 
            <Pie
              data={preparePieData('posicao')}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'top',
                    labels: {
                      font: {
                        size: 16,
                        family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
                      },
                      color: '#333',
                    },
                  },
                  title: {
                    display: true,
                    text: `Distribuição por Posição (${dados.length})`,
                    font: {
                      size: 20,
                      family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                      weight: 'bold',
                    },
                    color: '#333',
                  },
                  datalabels: {
                    color: '#fff',
                    font: {
                      size: 14,
                    },
                    formatter: (value, context) => {
                      const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
                      const percentage = ((value / total) * 100).toFixed(1);
                      return percentage > 0.1 ? `${percentage}%` : null;
                    },
                  },
                },
                animation: {
                  animateScale: true,
                  animateRotate: true,
                },
              }}
              height={320}
              width={400}
            />
          }
        </div>

        {/* Gráfico de Pizza de Score */}
        <div className="chart">
          <Pie
            data={prepareScorePieData()}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'top',
                  labels: {
                    font: {
                      size: 16,
                      family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
                    },
                    color: '#333',
                  },
                },
                title: {
                  display: true,
                  text: `Distribuição por Faixa de Score (${dados.length})`,
                  font: {
                    size: 20,
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                    weight: 'bold',
                  },
                  color: '#333',
                },
                datalabels: {
                  color: '#fff',
                  font: {
                    size: 14,
                  },
                  formatter: (value, context) => {
                    const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
                    const percentage = ((value / total) * 100).toFixed(1);

                    return percentage > 0.1 ? `${percentage}%` : null;
                  },
                },
              },
              animation: {
                animateScale: true,
                animateRotate: true,
              },
            }}
            height={320}
            width={400}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
