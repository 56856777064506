import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Header.css';
import logo from '../assets/img/logo.png';
import { FaUserLock } from "react-icons/fa6";


const Header = ({ userData }) => {
  const emailName = userData.email.split('@')[0];
  const [ip, setIp] = useState('');

  useEffect(() => {
    // Fazer uma requisição GET para a API de IP
    axios.get('https://devkaioribeiro.com/apidipp/api_sec/get_ip_user.php')
      .then(response => {
        setIp(response.data.ip);
      })
      .catch(error => {
        console.error("Erro ao buscar o IP:", error);
      });
  }, []);


  return (
    <header className="header">
      <div className="header-content">
        <img src={logo} alt="Logo" className="header-logo" />
        <h1>DIPP</h1>
      </div>
      <div className="user-info">
        <h5><FaUserLock style={{marginRight:10}} size={20}/> {emailName}</h5>
       {ip &&  <p className="additional-text">IP: <b>{ip}</b> </p>}
      </div>
    </header>
  );
};

export default Header;
