import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
          <p>&copy; <small style={{opacity:0.5, fontWeight:'bold', letterSpacing:1}}>&nbsp;DIPP 2024 - SIPE / SISTEMA DE INTELIGÊNCIA PENITENCIÁRIA E ESTATÍSTICA</small></p>
    </footer>
  );
};

export default Footer;
