import React from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';

const Sidebar = ({handleLogout}) => {
  return (
    <div className="sidebar">
      <nav>
        <ul>
          <li>
            <Link to="/dashboard">DASHBOARD</Link>
          </li>
          <li>
            <Link to="/listagem">LISTAGEM</Link>
          </li>
          <li>
            <Link to="/listagem">DPRES</Link>
          </li>
          <li>
            <Link to="/cadastro">CADASTRO</Link>
          </li>
          <li>
            <Link to="/cadastro">CRIPTO</Link>
          </li>
          <li>
            <Link to="/cadastro">USUÁRIOS</Link>
          </li>
          <li>
            <Link to="/cadastro">CONFIG</Link>
          </li>
          <li>
            <Link to="/cadastro">GUARDIÃO</Link>
          </li>
         
          <li>
            <Link onClick={handleLogout}>LOGOUT</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
