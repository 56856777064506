import React from 'react';
import './SkeletonLoader.css';

const SkeletonLoader = () => {
  return (
    <div className="skeleton-wrapper">
      <div className="skeleton-select"></div>
      <br></br>
      <div className="skeleton-container">
        <div className="skeleton-chart"></div>
        <div className="skeleton-chart"></div>
        <div className="skeleton-chart"></div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
