import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ModalEdit.css';


const getBackgroundColor = (totalValue) => {
  const limits = [
    { max: 50, color: '#00FF00' },
    { max: 100, color: '#FFFF00' },
    { max: 150, color: '#FFA500' },
    { max: 200, color: '#ff8100' },
    { max: 300, color: '#FF0000' }
  ];

  const interpolateColor = (color1, color2, factor) => {
    const hexToRgb = (hex) => hex.match(/\w\w/g).map(x => parseInt(x, 16));
    const rgbToHex = (rgb) => `#${rgb.map(x => x.toString(16).padStart(2, '0')).join('')}`;

    const [r1, g1, b1] = hexToRgb(color1);
    const [r2, g2, b2] = hexToRgb(color2);

    const r = Math.round(r1 + (r2 - r1) * factor);
    const g = Math.round(g1 + (g2 - g1) * factor);
    const b = Math.round(b1 + (b2 - b1) * factor);

    return rgbToHex([r, g, b]);
  };

  for (let i = 0; i < limits.length - 1; i++) {
    if (totalValue <= limits[i].max) {
      const rangeStart = i === 0 ? 0 : limits[i - 1].max;
      const rangeEnd = limits[i].max;
      const factor = (totalValue - rangeStart) / (rangeEnd - rangeStart);
      const colorStart = i === 0 ? limits[0].color : limits[i - 1].color;
      const colorEnd = limits[i].color;
      return interpolateColor(colorStart, colorEnd, factor);
    }
  }
  return limits[limits.length - 1].color;
};


const ModalEdit = ({ isOpen, onClose, presoId }) => {
  const [presoData, setPresoData] = useState(null);
  const [page, setPage] = useState(1); // Página inicial definida como 1
  const [advogados, setAdvogados] = useState([])
  const [presosMesmoProcesso, setPresosMesmoProcesso] = useState([])
  const [pessoasComVinculo, setPessoasComVinculo] = useState([])
  const [selecao, setSelecao] = useState([])
  const [telefones, setTelefones] = useState([])
  const [dataHoje, setDataHoje] = useState()
  const [diasDiferencaFimPrisao, setDiasDiferencaFimPrisao] = useState('');
  const [diasDiferencaProgressao, setDiasDiferencaProgressao] = useState('');
  const [dataFimPrisao, setDataFimPrisao] = useState('')
  const [dataProgressao, setDataProgressao] = useState('')

  const convertToDate = (dateString) => {
    const [day, month, year] = dateString.split('/'); // Divide a string em partes
    return new Date(`${year}-${month}-${day}`); // Cria o objeto Date no formato YYYY-MM-DD
  };


  const fetchPresoData = async () => {
    if (!presoId) return;

    try {
      const response = await axios.get(`https://devkaioribeiro.com/apidipp/list_preso_id.php?id=${presoId}`);
      console.log('Dados do preso recebidos:', response.data);

      // Define os dados principais do preso
      setPresoData(response.data);
      setDataFimPrisao(response.data.dataFimPrisao || null);
      setDataProgressao(response.data.dataProgressao || null);

      // Advogados
      try {
        if (typeof response.data.advogados === 'string') {
          setAdvogados(JSON.parse(response.data.advogados));
        } else if (Array.isArray(response.data.advogados)) {
          setAdvogados(response.data.advogados);
        } else {
          setAdvogados([]);
        }
      } catch (error) {
        console.error('Erro ao processar advogados:', error);
        setAdvogados([]);
      }

      // Presos do mesmo processo
      try {
        if (typeof response.data.presos === 'string') {
          setPresosMesmoProcesso(JSON.parse(response.data.presos));
        } else if (Array.isArray(response.data.presos)) {
          setPresosMesmoProcesso(response.data.presos);
        } else {
          setPresosMesmoProcesso([]);
        }
      } catch (error) {
        console.error('Erro ao processar presos do mesmo processo:', error);
        setPresosMesmoProcesso([]);
      }

      // Pessoas com vínculo
      try {
        if (typeof response.data.pessoasEnvolvimento === 'string') {
          setPessoasComVinculo(JSON.parse(response.data.pessoasEnvolvimento));
        } else if (Array.isArray(response.data.pessoasEnvolvimento)) {
          setPessoasComVinculo(response.data.pessoasEnvolvimento);
        } else {
          setPessoasComVinculo([]);
        }
      } catch (error) {
        console.error('Erro ao processar pessoas com vínculo:', error);
        setPessoasComVinculo([]);
      }

      // Telefones
      try {
        if (typeof response.data.telefones === 'string') {
          setTelefones(JSON.parse(response.data.telefones));
        } else if (Array.isArray(response.data.telefones)) {
          setTelefones(response.data.telefones);
        } else {
          setTelefones([]);
        }
      } catch (error) {
        console.error('Erro ao processar telefones:', error);
        setTelefones([]);
      }

      // Seleção
      try {
        if (typeof response.data.selecao === 'string') {
          setSelecao(JSON.parse(response.data.selecao));
        } else if (Array.isArray(response.data.selecao)) {
          setSelecao(response.data.selecao);
        } else {
          setSelecao([]);
        }
      } catch (error) {
        console.error('Erro ao processar seleção:', error);
        setSelecao([]);
      }

    } catch (error) {
      console.error('Erro ao carregar os dados do preso:', error);
    }
  };

  // Cálculo da diferença de dias para fim da prisão
  useEffect(() => {
    if (dataFimPrisao) {
      const dataFimPrisaoDate = convertToDate(dataFimPrisao); // Converte para objeto Date
      const today = new Date(); // Data de hoje

      if (!isNaN(dataFimPrisaoDate) && !isNaN(today)) {
        const timeDifference = dataFimPrisaoDate - today;
        const differenceInDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
        setDiasDiferencaFimPrisao(differenceInDays);
        console.log('Diferença de dias até o fim da prisão:', differenceInDays);
      } else {
        console.log('Uma das datas é inválida');
      }
    }
  }, [dataFimPrisao]);

  // Cálculo da diferença de dias para progressão
  useEffect(() => {
    if (dataProgressao) {
      const dataProgressaoDate = convertToDate(dataProgressao); // Converte para objeto Date
      const today = new Date(); // Data de hoje

      if (!isNaN(dataProgressaoDate) && !isNaN(today)) {
        const timeDifference = dataProgressaoDate - today;
        const differenceInDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
        setDiasDiferencaProgressao(differenceInDays);
        console.log('Diferença de dias até a progressão:', differenceInDays);
      } else {
        console.log('Uma das datas é inválida');
      }
    }
  }, [dataProgressao]);


  const changePage = (page) => {
    setPage(page); // Altera a página com base no valor fornecido
  };



  useEffect(() => {
    if (isOpen && presoId) {
      fetchPresoData();
    }
  }, [isOpen, presoId]); // Atualiza sempre que o modal abrir e o presoId mudar

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(); // Fechar o modal se clicar fora da área do conteúdo
    }
  };

  if (!isOpen || !presoData) return null; // Certifica-se de que o modal só será renderizado quando necessário

  return (
    <div>
      <div className="modal-overlay" onClick={handleOverlayClick}>
        <div className="modal-container" onClick={(e) => e.stopPropagation()}>
          <button className="close-button" onClick={() => (onClose(), setPresoData([]))}>
            X
          </button>

          <div className="input-group">
            <div
              className="image-and-info"
              style={{ display: 'flex', alignItems: 'center', gap: '20px', height: '260px' }}
            >
              {presoData.imagem ? (
                <img
                  src={`https://devkaioribeiro.com/apidipp/${presoData.imagem}`}
                  alt="Imagem do Preso"
                  className="image-preview"
                  style={{
                    height: 200,

                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    borderBottom: `12px solid ${getBackgroundColor(presoData.score)}`, // Apenas borda inferior
                  }}
                />

              ) : (
                <img
                  src=""
                  alt="Imagem Placeholder"
                  className="image-preview"
                  style={{
                    height: 200,
                    borderRadius: '8px',
                    border: '1px solid #ccc',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    opacity: 0.4,
                  }}
                />
              )}

              <div
                className="info-section"
                style={{
                  display: 'flex',
                  flexDirection: 'column',

                  lineHeight: '18px'
                }}
              >
                <div style={{ lineHeight: '18px' }}>
                  <label htmlFor="nome" style={{ marginBottom: -18 }}>Nome</label>
                  <h2 style={{ fontSize: 26 }}>{presoData.nome}</h2>

                  <label htmlFor="alcunha" style={{ marginBottom: -16, marginTop: "-10px" }}>Alcunha</label>
                  <h2 style={{ fontSize: 24 }}>{presoData.alcunha}</h2>

                  <label htmlFor="faccao" style={{ marginBottom: -16 }}>Facção</label>
                  <h2 style={{ fontSize: 24 }}>
                    {presoData.faccaoCriminosa.toUpperCase()} ({presoData.posicao.toUpperCase()})
                  </h2>
                </div>
                <div style={{ display: 'flex', justifyContent: 'left', textAlign: 'left', flexDirection: 'row', gap: 30 }}>
                  <div><label htmlFor="infopen" style={{ marginBottom: -18 }}>Score</label>
                    <h2 style={{ fontSize: 24 }}>
                      {presoData.score}
                    </h2></div>

                  <div> <label htmlFor="infopen" style={{ marginBottom: -18 }}>Localização</label>
                    <h2 style={{ fontSize: 24 }}>
                      {presoData.localizacaoAtual.toUpperCase()}
                    </h2></div>

                  <div><label htmlFor="infopen" style={{ marginBottom: -18 }}>Infopen</label>
                    <h2 style={{ fontSize: 24 }}>
                      {presoData.infopen}
                    </h2></div>
                  <div></div>
                </div>
              </div>
            </div>


            <br></br>
            {/* Abas */}
            <div className="tabs-container">
              <button
                className={`tab-button ${page === 1 ? 'active' : ''}`}
                onClick={() => changePage(1)}
              >
                Dados
              </button>
              <button
                className={`tab-button ${page === 2 ? 'active' : ''}`}
                onClick={() => changePage(2)}
              >
                Prisão
              </button>
              <button
                className={`tab-button ${page === 3 ? 'active' : ''}`}
                onClick={() => changePage(3)}
              >
                Social
              </button>
              <button
                className={`tab-button ${page === 4 ? 'active' : ''}`}
                onClick={() => changePage(4)}
              >
                Perfil
              </button>
              <button
                className={`tab-button ${page === 5 ? 'active' : ''}`}
                onClick={() => changePage(5)}
              >
                <small><b>DPRES</b></small>
              </button>
            </div>

            {/* Renderização Condicional das Páginas */}
            {page === 1 && (
              <div className="ficha-container">
                <div className="ficha-item">
                  <label htmlFor="mae">Mãe</label>
                  <input
                    id="mae"
                    name="mae"
                    type="text"
                    value={presoData.mae || ''}
                    readOnly
                  />
                </div>
                <div className="ficha-item">
                  <label htmlFor="pai">Pai</label>
                  <input
                    id="pai"
                    name="pai"
                    type="text"
                    value={presoData.pai || ''}
                    readOnly
                  />
                </div>

                <div className="ficha-item">
                  <label htmlFor="areaAtuacao">Atuação Criminal</label>
                  <input
                    id="areaAtuacao"
                    name="areaAtuacao"
                    type="text"
                    value={presoData.areaAtuacao || ''}
                    readOnly
                  />
                </div>
                <div className="ficha-item">
                  <label htmlFor="infoPessoal">Endereço</label>
                  <input
                    id="infoPessoal"
                    name="infoPessoal"
                    type="text"
                    value={presoData.infoPessoal || ''}
                    readOnly
                  />
                </div>

                <div className="ficha-item">
                  <label htmlFor="cpf">CPF</label>
                  <input
                    id="cpf"
                    name="cpf"
                    type="text"
                    value={presoData.cpf || ''}
                    readOnly
                  />
                </div>

                <div className="ficha-item">
                  <label htmlFor="identidade">Identidade</label>
                  <input
                    id="identidade"
                    name="identidade"
                    type="text"
                    value={presoData.identidade || ''}
                    readOnly
                  />
                </div>

                <div className="ficha-item">
                  <label htmlFor="cnh">CNH</label>
                  <input
                    id="cnh"
                    name="cnh"
                    type="text"
                    value={presoData.cnh || ''}
                    readOnly
                  />
                </div>
                <div className="ficha-item">
                  <label htmlFor="dataNascimento">Nascimento</label>
                  <input
                    id="dataNascimento"
                    name="dataNascimento"
                    type="text"
                    value={presoData.dataNascimento || ''}
                    readOnly
                  />
                </div>
              <br></br></div>
            )}

            {page === 2 && (
              <div className="ficha-container">
                <div className="ficha-item">
                  <label htmlFor="dataPrisao">Data da Prisão</label>
                  <input
                    id="dataPrisao"
                    name="dataPrisao"
                    type="text"
                    value={presoData.dataPrisao || ''}
                    readOnly
                  />
                </div>
                <div className="ficha-item">
                  <label htmlFor="dataProgressao">Data da Progressão {diasDiferencaProgressao > 1 && '(Restam ' + diasDiferencaProgressao + ' dias)'}</label>
                  <input
                    id="dataProgressao"
                    name="dataProgressao"
                    type="text"
                    value={presoData.dataProgressao || ''}
                    readOnly
                  />
                </div>
                <div className="ficha-item">
                  <label htmlFor="dataFimPrisao">Fim da Prisão {diasDiferencaFimPrisao > 1 && '(Restam ' + diasDiferencaFimPrisao + ' dias)'}</label>
                  <input
                    id="dataFimPrisao"
                    name="dataFimPrisao"
                    type="text"
                    value={presoData.dataFimPrisao || ''}
                    readOnly
                  />
                </div>
                <div className="ficha-item">
                  <label htmlFor="localizacaoAtual">Localização</label>
                  <input
                    id="localizacaoAtual"
                    name="localizacaoAtual"
                    type="text"
                    value={presoData.localizacaoAtual || ''}
                    readOnly
                  />
                </div>
                <div className="ficha-item">
                  <label htmlFor="artigos">Artigos</label>
                  <input
                    id="artigos"
                    name="artigos"
                    type="text"
                    value={presoData.artigos || ''}
                    readOnly
                  />
                </div>
                <div className="ficha-item">
                  <label htmlFor="galeriaMonitorada">Galeria Monitorada</label>
                  <input
                    id="galeriaMonitorada"
                    name="galeriaMonitorada"
                    type="text"
                    value={(presoData.galeriaMonitorada || '').toUpperCase()}
                    readOnly
                  />
                </div>
                <div className="ficha-item">
                  <label htmlFor="advogados">Advogados</label>
                  <div className="ficha-item">
                    {console.log('Renderizando advogados:', advogados)}
                    {advogados.length > 0 ? (
                      <textarea
                        style={{ fontFamily: 'arial' }}
                        id="advogados"
                        name="advogados"
                        value={advogados
                          .map((advogado) => `${advogado.nome} / OAB: ${advogado.oab}`)
                          .join('\n')} // Junta todos os dados dos advogados em uma única string, separados por nova linha
                        readOnly
                        rows='3' // Ajusta o número de linhas com base na quantidade de advogados
                      />
                    ) : (
                      <textarea
                        style={{ fontFamily: 'arial' }}
                        id="semRegistros"
                        name="semRegistros"
                        value="Nenhum Advogado Registrado"
                        readOnly
                      />
                    )}

                  </div>

                </div>
                <div className="ficha-item">
                  <label htmlFor="presosMesmoProcesso">Presos no Mesmo Processo</label>
                  <div className="ficha-item">
                    {console.log('Renderizando presos:', presosMesmoProcesso)}
                    {presosMesmoProcesso.length > 0 ? (
                      <textarea
                        style={{ fontFamily: 'arial' }}
                        id="presosMesmoProcesso"
                        name="presosMesmoProcesso"
                        value={presosMesmoProcesso
                          .map((preso) => `${preso.nome} - Infopen: ${preso.infopen} / Processo: ${preso.processo}`)
                          .join('\n')} // Junta todos os dados em uma única string separada por nova linha
                        readOnly
                        rows='3' // Ajusta o número de linhas com base na quantidade de presos
                      > </textarea>
                    ) : (
                      <textarea
                        style={{ fontFamily: 'arial' }}
                        id="semRegistros"
                        name="semRegistros"
                        value="Nenhum Preso Registrado"
                        readOnly
                        rows='4'
                      />
                    )}
                  </div>
                </div>



              </div>
            )}

            {page === 3 && (
              <div className="ficha-container">
                <div className="ficha-item">
                  <label htmlFor="Facebook">Facebook</label>
                  <input
                    id="Facebook"
                    name="Facebook"
                    type="text"
                    value={(presoData.facebook || '')}
                    readOnly
                  />
                </div>
                <div className="ficha-item">
                  <label htmlFor="Instagram">Instagram</label>
                  <input
                    id="Instagram"
                    name="Instagram"
                    type="text"
                    value={(presoData.instagram || '')}
                    readOnly
                  />
                </div>
                <div className="ficha-item">
                  <label htmlFor="Instagram">Twitter</label>
                  <input
                    id="Twitter"
                    name="Twitter"
                    type="text"
                    value={(presoData.twitter || '')}
                    readOnly
                  />
                </div>
                <div className="ficha-item">
                  <label htmlFor="Instagram">TikTok</label>
                  <input
                    id="TikTok"
                    name="TikTok"
                    type="text"
                    value={(presoData.tiktok || '')}
                    readOnly
                  />
                </div>
                <div className="ficha-item">
                  <label htmlFor="presosMesmoProcesso">Vínculos Pessoais</label>
                  <div className="ficha-item">
                    {console.log('Renderizando presos:', pessoasComVinculo)}
                    {pessoasComVinculo.length > 0 ? (
                      <textarea
                        style={{ fontFamily: 'arial' }}
                        id="pessoasComVinculo"
                        name="presosMesmoProcesso"
                        value={pessoasComVinculo
                          .map((preso) => `NOME: ${preso.nome} - RELAÇÃO: ${preso.relacao} - OBSERVAÇÃO: ${preso.obs}`)
                          .join('\n\n')} // Junta todos os dados em uma única string separada por nova linha
                        readOnly
                        rows='9'// Ajusta o número de linhas com base na quantidade de presos
                      />
                    ) : (
                      <textarea
                        style={{ fontFamily: 'arial' }}
                        id="semRegistros"
                        name="semRegistros"
                        value="Nenhum Preso Registrado"
                        readOnly
                        rows='9'
                      />
                    )}
                  </div>
                </div>
                <div className="ficha-item">
                  <label htmlFor="presosMesmoProcesso">Telefones</label>
                  <div className="ficha-item">
                    {console.log('Renderizando presos:', telefones)}
                    {telefones.length > 0 ? (
                      <textarea
                        style={{ fontFamily: 'arial' }}
                        id="telefones"
                        name="telefones"
                        value={telefones
                          .map((preso) => `${preso.telefone} - OBSERVAÇÃO: ${preso.obs}`)
                          .join('\n')} // Junta todos os dados em uma única string separada por nova linha
                        readOnly
                        rows='9' // Ajusta o número de linhas com base na quantidade de presos
                      />
                    ) : (
                      <textarea
                        style={{ fontFamily: 'arial' }}
                        id="semRegistros"
                        name="semRegistros"
                        value="Nenhum Preso Registrado"
                        readOnly
                      />
                    )}
                  </div>
                </div>
              </div>
            )}

            {page === 4 && (
              <div className="ficha-container">
                <div className="ficha-item" style={{ width: '720px', lineHeight: '20px' }}> <label htmlFor="selecao">Perfil Criminal</label>
                  {console.log('Renderizando presos:', selecao)}
                  {selecao.length > 0 ? (
                    <textarea
                      style={{ fontSize: 16, fontFamily: 'arial' }}
                      id="selecao"
                      name="selecao"
                      value={selecao
                        .map((preso) => `- ${preso}   `)
                        .join('\n')} // Junta todos os dados em uma única string separada por nova linha
                      readOnly
                      rows='20' // Ajusta o número de linhas com base na quantidade de presos
                    />
                  ) : (
                    <textarea
                      id="semRegistros"
                      name="semRegistros"
                      value="Nenhum Preso Registrado"
                      readOnly
                    />
                  )}
                </div>


              </div>
            )}






          </div>
        </div>
      </div></div>
  );
};

export default ModalEdit;
