import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Login from './pages/Login';
import Cadastro from './pages/Cadastro';
import Dashboard from './pages/Dashboard';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Footer from './components/Footer';
import PresosList from './pages/PresosList';
import Editar from './pages/Editar';
import './App.css';

function AppContent() {
  const location = useLocation();
  const navigate = useNavigate();
  const isLoginRoute = location.pathname === '/';
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true); // Novo estado de carregamento

  // Carregar os dados do localStorage ao montar o componente
  useEffect(() => {
    const savedUserData = localStorage.getItem('userData');
    if (savedUserData) {
      try {
        const parsedData = JSON.parse(savedUserData);
        setUserData(parsedData);
        console.log("Dados restaurados do localStorage:", parsedData); // Verifique o que está sendo restaurado
      } catch (error) {
        console.error("Erro ao restaurar os dados do localStorage:", error);
      }
    }
    setLoading(false); // Finaliza o carregamento após tentar restaurar os dados
  }, []);

  // Função para salvar os dados do usuário no localStorage
  function LoginSetUser(userDataSend) {
    if (userDataSend) {
      console.log("Salvando dados do usuário:", userDataSend); // Verifique o que está sendo salvo
      setUserData(userDataSend);
      localStorage.setItem('userData', JSON.stringify(userDataSend)); // Salvar no localStorage
      navigate('/dashboard'); // Redirecionar para o dashboard após o login
    }
  }

  // Função de logout
  function handleLogout() {
    console.log("Realizando logout, removendo dados do localStorage");
    setUserData(null); // Reseta o estado
    localStorage.removeItem('userData'); // Remove do localStorage
    navigate('/'); // Redirecionar para a página de login
  }

  // Verifica se o userData está vazio e redireciona para o login
  useEffect(() => {
    if (!loading && !userData && !isLoginRoute) { // Adicionado verificação de 'loading'
      console.log("Nenhum dado de usuário encontrado, redirecionando para o login");
      navigate('/');
    }
  }, [userData, isLoginRoute, loading, navigate]); // Adicionado 'loading' como dependência

  if (loading) {
    // Enquanto estiver carregando, renderizar algo que indica o carregamento
    return <div>Carregando...</div>;
  }

  return (
    <div className="app-container">
      {!isLoginRoute && userData && <Header userData={userData} />}
      <div className="main-content">
        {!isLoginRoute && userData && <Sidebar handleLogout={handleLogout} />}
        <div className={!isLoginRoute ? 'content-with-sidebar' : 'content'}>
          <Routes>
            {/* Rotas Públicas */}
            <Route path="/" element={<Login LoginSetUser={LoginSetUser} />} />
            
            {/* Rotas Protegidas */}
            {userData && <Route path="/cadastro" element={<Cadastro />} />}
            {userData && <Route path="/listagem" element={<PresosList />} />}
            {userData && <Route path="/dashboard" element={<Dashboard />} />}
            {userData && <Route path="/editar" element={<Editar />} />}
          </Routes>
        </div>
      </div>
      {!isLoginRoute && userData && <Footer />}
     
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
