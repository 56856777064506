import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PresosList.css'; // Arquivo CSS para estilização
import Lottie from 'react-lottie';
import loadingAnimation from '../assets/animation/animation.json'; // Arquivo JSON da animação
import { IoEyeSharp } from "react-icons/io5";
import { FaFileAlt } from "react-icons/fa";
import { GiEagleHead } from "react-icons/gi";
import { MdEdit } from "react-icons/md";
import Skeleton from 'react-loading-skeleton';
import ModalEdit from '../components/ModalEdit';
import ModalAguia from '../components/ModalAguia';
import Editar from './Editar';
import 'react-loading-skeleton/dist/skeleton.css';

const PresosList = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
  };

  const [presos, setPresos] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // Input de busca antigo
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [selectedPresoId, setSelectedPresoId] = useState(null); // Estado para armazenar o preso selecionado
  const [isModalOpenAguia, setIsModalOpenAguia] = useState(false);
  const [presoId, setPresoId] = useState(null);

  const openModalAguia = (id) => {
    setPresoId(id);
    setIsModalOpenAguia(true);
  };

  const openModal = (id) => {
    setSelectedPresoId(id); // Define o preso selecionado
    setIsModalOpen(true);    // Abre o modal
  };

  const closeModal = () => {
    setIsModalOpen(false);   // Fecha o modal
    setSelectedPresoId(null); // Limpa o preso selecionado
  };
  const openModal2 = (id) => {
    setSelectedPresoId(id); // Define o preso selecionado
    setIsModalOpen2(true);    // Abre o modal
  };

  const closeModal2 = () => {
    fetchPresos()
    setIsModalOpen2(false);   // Fecha o modal
    setSelectedPresoId(null); // Limpa o preso selecionado

  };

  // Estados para cada filtro adicional
  const [filters, setFilters] = useState({
    faccaoCriminosa: '',
    localizacao: '',
    advogados: '',
    presos: '',
    pessoasEnvolvimento: ''
  });

  const getBackgroundColor = (totalValue) => {
    const limits = [
      { max: 50, color: '#00FF00' },
      { max: 100, color: '#FFFF00' },
      { max: 150, color: '#FFA500' },
      { max: 200, color: '#ff8100' },
      { max: 300, color: '#FF0000' }
    ];

    const interpolateColor = (color1, color2, factor) => {
      const hexToRgb = (hex) => hex.match(/\w\w/g).map(x => parseInt(x, 16));
      const rgbToHex = (rgb) => `#${rgb.map(x => x.toString(16).padStart(2, '0')).join('')}`;

      const [r1, g1, b1] = hexToRgb(color1);
      const [r2, g2, b2] = hexToRgb(color2);

      const r = Math.round(r1 + (r2 - r1) * factor);
      const g = Math.round(g1 + (g2 - g1) * factor);
      const b = Math.round(b1 + (b2 - b1) * factor);

      return rgbToHex([r, g, b]);
    };

    for (let i = 0; i < limits.length - 1; i++) {
      if (totalValue <= limits[i].max) {
        const rangeStart = i === 0 ? 0 : limits[i - 1].max;
        const rangeEnd = limits[i].max;
        const factor = (totalValue - rangeStart) / (rangeEnd - rangeStart);
        const colorStart = i === 0 ? limits[0].color : limits[i - 1].color;
        const colorEnd = limits[i].color;
        return interpolateColor(colorStart, colorEnd, factor);
      }
    }
    return limits[limits.length - 1].color;
  };

  // Função para buscar os dados da API
  const fetchPresos = async () => {
    try {
      const response = await axios.get('https://devkaioribeiro.com/apidipp/list_presos.php', {
        params: {
          search: searchTerm, // Envia o termo de busca antigo como parâmetro
          faccaoCriminosa: filters.faccaoCriminosa,
          localizacaoAtual: filters.localizacao,
          advogados: filters.advogados,
          presos: filters.presos,
          pessoasEnvolvimento: filters.pessoasEnvolvimento
        },
      });
      setPresos(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Erro ao buscar os dados:', error);
      setLoading(false);
    }
  };

  // useEffect para carregar os dados da API na montagem e quando o termo de busca ou os filtros mudarem
  useEffect(() => {
    fetchPresos();
  }, [searchTerm, filters]);

  // Função para lidar com mudanças no campo de busca
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Função para lidar com mudanças nos filtros
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  return (
    <div className="container">
      <div className="search-bar">
    

        {/* Adicionando novos filtros */}
        <div className="filter-bar">
        <input
            type="text"
            placeholder="Busque por nome, alcunha, CPF, etc..."
            value={presos.length}
           disabled
            style={{ width: '100px', textAlign:"center" }}
          />
        <input
            type="text"
            placeholder="Busque por nome, alcunha, CPF, etc..."
            value={searchTerm.toUpperCase()}
            onChange={handleSearchChange}
            style={{ width: '580px' }}
          />
          <input
            type="text"
            name="faccaoCriminosa"
            placeholder="Facção"
            value={filters.faccaoCriminosa.toUpperCase()}
            onChange={handleFilterChange}
            style={{ width: '200px' }}
          />
          <input
            type="text"
            name="localizacao"
            placeholder="Localização"
            value={filters.localizacao.toUpperCase()}
            onChange={handleFilterChange}
          />
          <input
            type="text"
            name="advogados"
            placeholder="Advogados"
            value={filters.advogados.toUpperCase()}
            onChange={handleFilterChange}
          />
         
          <input
            type="text"
            name="pessoasEnvolvimento"
            placeholder="Pessoas Envolvidas"
            value={filters.pessoasEnvolvimento.toUpperCase()}
            onChange={handleFilterChange}
          />
        </div>
      </div>
      <br /><br /><br />
     
      <br></br>
      {loading ? (
   <>
          {console.log('Skeleton is loading')}
          <table className="table">
          <thead>
            <tr>
              <th></th>
              <th>Nome</th>
              <th>Alcunha</th>
              <th>Infopen</th>
              <th>Localização</th>
              <th>Facção</th>
              <th>Artigos</th>
              <th></th>
            </tr>
          </thead>
            <tbody>
              {Array(10).fill().map((_, index) => (
                <tr key={index}>
                  <td><Skeleton square={true} height={60} width={60} baseColor="#d4d4d4" highlightColor="#f0f0f0" /></td>
                  <td><Skeleton width={250} height={40} baseColor="#d4d4d4" highlightColor="#f0f0f0" /></td>
                  <td><Skeleton width={150} height={36} baseColor="#d4d4d4" highlightColor="#f0f0f0" /></td>
                  <td><Skeleton width={150} height={36} baseColor="#d4d4d4" highlightColor="#f0f0f0" /></td>
                  <td><Skeleton width={150} height={36} baseColor="#d4d4d4" highlightColor="#f0f0f0" /></td>
                  <td><Skeleton width={150} height={36} baseColor="#d4d4d4" highlightColor="#f0f0f0" /></td>
                  <td><Skeleton width={250} height={36} baseColor="#d4d4d4" highlightColor="#f0f0f0" /></td>
                  <td><Skeleton width={250} height={36} baseColor="#d4d4d4" highlightColor="#f0f0f0" /></td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
) :  (
        <table className="table">
         {presos.length > 0 && <thead>
            <tr>
              <th></th>
              <th>Nome</th>
              <th>Alcunha</th>
              <th>Infopen</th>
              <th>Localização</th>
              <th>Facção</th>
              <th>Artigos</th>
              <th></th>
            </tr>
          </thead>}
          <tbody>
            {presos.length > 0 ? (
              presos.map((preso) => {
                const borderColor = getBackgroundColor(preso.score); // Chama a função para obter a cor da borda

                return (
                  <tr key={preso.id}>
                    <td style={{ width: '5%' }}>
                      <div className="image-container">
                        <img
                          src={`https://devkaioribeiro.com/apidipp/${preso.imagem}`}
                          alt={preso.nome}
                          className="profile-image"
                          style={{borderBottom: `6px solid ${getBackgroundColor(preso.score)}` }} // Aplica a cor da borda dinamicamente
                        />
                        <div className="score-label">Score: {preso.score}</div>
                      </div>
                    </td>
                    <td style={{ width: '26%' }}>{preso.nome}</td>
                    <td style={{ width: '10%' }}>{preso.alcunha}</td>
                    <td style={{ width: '10%' }}>
                      <a
                        target='_BLANK'
                        href={`https://infopen.sejus.es.gov.br/Identificacao/Presos.aspx?ID=${preso.infopen}`}
                        className="infopen-link"
                      >
                        {preso.infopen}
                      </a>
                    </td>
                    <td style={{ width: '8%' }}>{preso.localizacaoAtual}</td>
                    <td style={{ width: '8%' }}>{preso.faccaoCriminosa}</td>
                    <td style={{ fontSize: 11, width: '15%' }}>{preso.artigos}</td>
                    <td style={{ fontSize: 12, width: '19%' }}>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'row',
                      }}>
                        <button
                          alt="Cadastro"
                          title='Cadastro'
                          onClick={() => openModal(preso.id)}
                          className="btnAction">
                          <IoEyeSharp />

                        </button>
                                              <button alt="DPRES" title='DPRES' className="btnAction"><FaFileAlt />
                        </button>
                        <button alt="Halk_1" title='Halk_1' className="btnAction" onClick={() => openModalAguia(preso.id)}><GiEagleHead />

                        </button>
                        <button alt="Edição" title='Edição' className="btnAction" onClick={() => openModal2(preso.id)}><MdEdit/>
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr class="no-data">
              <td>Nenhum dado encontrado</td>
            </tr>
            )}
          </tbody>
        </table>
      )}
          
      
      {isModalOpenAguia && (
        <ModalAguia isOpen={isModalOpenAguia} onClose={() => setIsModalOpenAguia(false)} presoId={presoId} />
      )}

      {/* Render the modal outside the table to ensure only one modal is displayed at a time */}
      {isModalOpen && (
        <ModalEdit isOpen={isModalOpen} onClose={closeModal} presoId={selectedPresoId} />
      )}
      {isModalOpen2 && (
        <Editar isOpen={isModalOpen2} onClose={closeModal2} idDoPreso={selectedPresoId} />
      )}
    </div>
  );
};

export default PresosList;
